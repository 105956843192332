import React from 'react'
import { connect } from 'react-redux'
import '../css/profile.css'
import axios from 'axios';
import qs from 'qs';
import config from '../config'
//  import youjian from '../../public/img/youjian.jpg'
import { toggleUserDropdown } from '../redux/Actions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination } from '@material-ui/core';
import AddStudent from '../components/AddStudent'
import ClassManage from '../components/classManage'
import StudentHomework from '../components/studentHomework'
import StudentClass from '../components/studentClass'
import TestManage from '../components/testManage'
import Homework from '../components/homework'

const mapDispatchToProps = dispatch => ({
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});
const mapStateToProps = state => ({
  banners: state.banners,
  app: state.app
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F3F4F6",
    color: '#000',
    fontSize: 16,
    fontWeight: "600"
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: "#f1f1f1",
    },
  },
}))(TableRow);

const table = {
  minWidth: 700,
  padding: 30
}
// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
class profile extends React.Component {
  state = {
    userId: '',
    loading: true,
    permissions: false,
    spermissions:false,
    border_color: false,  // 新密码边框
    cancle_color: false,  // 确认密码边框
    old_password: '',      // 旧密码
    new_password: '',      // 新密码
    password: '',      // 确认密码
  }

  componentDidMount() {
    window.ServerIp = config.env.edu;
    document.title = "个人中心"
    var _this = this
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    if (arr) {
      this.props.history.push('/login')
    }
    else {
      axios.defaults.withCredentials = true;
      axios.get(`${config.env.server}getcookieuserid`).then(res => {
        if (res.data[0][0]) {
          window.UserId = res.data[0][0].userid;
          window.language = _this.props.language
          let show = false
          let show2 = false
          if (res.data[0][0].identity === 2 || res.data[0][0].identity === 0) {
            show = true;
          }

          if (res.data[0][0].identity === 1) {
            show2 = true;
          }

          _this.setState({
            userId: res.data[0][0].userid,
            permissions: show,
            spermissions: show2
          });
          const script = document.createElement('script');
          script.type = "text/javascript";
          script.src = "/profileF.js";
          script.async = "async";
          script.onload = function () {
            window.eduonload();
            _this.setState({
              loading: false
            })
          }
          document.body.appendChild(script);
        }
      }).catch(err => {
        _this.props.history.push('/login');
        // window.alert(err.response.data)
      })
    }
  }

  editPassword = () => {
    let params = {
      userid: this.state.userId,
      password: this.state.old_password,
      enterPassword: this.state.new_password
    }
    axios.post(`${config.env.edu}profile/user/updatepassword`, qs.stringify(params)).then(res => {
      if (res.status === 200) {
        alert("密码修改成功")
      } else {
        alert("密码修改失败")
      }
    }).catch(err => {
      alert("密码修改失败")
    })
  }

  componentWillUnmount = () => {
    window.sessionStorage.setItem('navClick', 0)
  }

  render() {
    const { permissions, spermissions, userId } = this.state
    const { language } = this.props
    return (
      <div className="p_page">
        <div className="p_nav">
          <div className="divhover"><img src="/img/profile-2.png" alt="" />{language.personal_center}</div>
          <div><img src="/img/notice-1.png" alt="" />{language.notification_center}</div>
          <div><img src="/img/text-1.png" alt="" />{language.data_management}</div>
          <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/class-1.png" alt="" />{language.course_managment}</div>
          <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/home-1.png" alt="" />{language.room_managment}</div>
          {/* <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/home-1.png" alt="" />{language.school_managment}</div> */}
          {/* <div><img src="/img/home-1.png" alt=""/>{language.friend_group}</div> */}
          <div><img src="/img/text-1.png" alt="" />修改密码</div>
          <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/classes-1.png" alt="" />班级管理</div>
          <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/xue-1.png" alt="" />学生列表</div>
          <div><img src="/img/class-1.png" alt="" />我的课程</div>
          <div><img src="/img/homework-1.png" alt="" />我的作业</div>
          {/* <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/ceyan-1.png" alt="" />测验管理</div>
          <div style={permissions ? { display: "block" } : { display: "none" }}><img src="/img/bian-1.png" alt="" />编程作业</div> */}
        </div>
        <div className="p_bjshow">
          <div className="p_table">
            {/**個人中心 */}
            <div className="p_table_block">
              <div className="hp_main">
                <div className="hp_main_box">
                  <div className="pb_head">学习中课程<span className="hp_My">&nbsp;&nbsp;Learning</span></div>
                  <div className="hp_big">
                  </div>
                  <div className="learning"></div>
                </div>
                <div className="hp_main_box" style={permissions ? { display: "block" } : { display: "none" }}>
                  <div className="pb_head">我的上传<span className="hp_My">&nbsp;&nbsp;My</span></div>
                  <div className="hp_big">
                  </div>
                  <div className="scpage"></div>
                </div>
                <div className="hp_main_box" style={permissions ? { display: "block" } : { display: "none" }}>
                  <div className="pb_head">我的订阅
                    <span className="hp_My">&nbsp;&nbsp;Subscribe</span></div>
                  <div className="hp_big ">
                  </div>
                  <div className="dypage"></div>
                </div>
                <div className="hp_main_box">
                  <div className="pb_head">我的收藏<span className="hp_My">&nbsp;&nbsp;Collect</span></div>
                  <div className="hp_big ">
                  </div>
                  <div className="shoucpage"></div>
                </div>
              </div>
            </div>
            {/**通知中心 */}
            <div className="p_table_div">
              <div>
                <div className="pb_head">通知中心<span className="hp_My">&nbsp;&nbsp;Notifications</span></div>
                <div className="content_">
                </div>
                <div className="newscontent_page"></div>
                {/* <button className="content_button">返回首頁</button> */}
              </div>
            </div>
            {/*資料管理*/}
            <div className="p_table_div">
              <div style={{ marginLeft: '35px' }}>
                <div className="pb_head" style={{ marginLeft: '0px' }}>
                  <span>我的资料<span className="hp_My">&nbsp;&nbsp;Profile</span></span>
                </div>
                <div className="my_all">
                  <div className="my_name">
                    <div>
                      <span>{language.name}：</span>
                      <input type="text" className="my_text" id="my_name" placeholder={language.input_name} />
                    </div>
                  </div>
                  <div className="my_name">
                    <div style={{ width: "100%" }}>
                      <span>{language.personal_profile}：</span>
                      <textarea style={{ fontWeight: 600, font: '400 18px Arial' }} className="my_area" id='my_grjj' placeholder={language.personal_profile_input} />
                    </div>
                  </div>
                  <div className="my_name">
                    <div className="my_sf" id="jianju">
                      <span>{language.sex}：</span>
                      <input type="radio" name="fix" className='my_sex' /><i style={{ margin: '0 3px 0 0' }}></i>{language.men}<i style={{ margin: ' 0 15px 0 0' }}></i>
                      <input type="radio" name="fix" className='my_sex' /><i style={{ margin: '0 3px 0 0' }}></i>{language.women}
                    </div>
                    <div className="my_sf">
                      <span>{language.electronic_email}：</span>
                      <input type="text" placeholder={language.electronic_email} id='my_email' className="my_text" readonly="true"/>
                      {/* <span id='my_email' ></span> */}
                    </div>
                  </div>
                  <div className="my_name" >
                    <div className="my_sf" style={{ display: "none" }}>
                      <span>{language.school}：</span>
                      <select type="text" className="my_text" id='my_school'>
                      </select>
                    </div>
                    <div className="my_sf">
                      <span>{language.phone}：</span>
                      <input type="text" placeholder={language.input_phone} id='my_tel' className="my_text" />
                    </div>
                  </div>
                  <div className="my_name" style={{ margin: '30px 0 0 0' }}>
                    <div className="anniu">
                      <input type="button" value={language.edit} id="my_anniu" />
                    </div>
                  </div>
                  <div className="my_name" style={{ display: 'none' }}>
                    <div className="my_sf">
                      <span>身份：</span>
                      <select name="" id="sf">
                        <option value="0">教师</option>
                        <option value="1">学生</option>
                        <option value="2">管理员</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 課程管理 */}
            <div className="p_table_div p_course">
              <div className="pb_head">
                课程管理<span className="hp_My">&nbsp;&nbsp;Curriculum</span>
                <p className="p_course_countClass">n个课程</p>
                <button className="template_btn">创建模板课程</button>
              </div>
              {/*<div className='p_course_input' style={{ margin: '30px 30px 0 0px', height: '36px' }}>
                <div className='p_course_search'></div>
                <input className="p_course_searchI" placeholder={language.course_name} />
                style={{ float: 'right', width: '200px', marginTop: '5px', height: '30px', fontSize: '18px', textIndent: '10px' }}
              </div>*/}
              <div className='p_course_searchBox'>
                <select className="p_course_select">
                  <option value="1" selected>我的课程</option>
                  <option value="2">分享课程</option>
                  <option value="3">他人课程</option>
                </select>
                <div className="search"><input placeholder="请输入账号、姓名" id="p_course_searchV" /> <button id="p_course_searchBtn">查询</button></div>
              </div>
              <div className='p_course_all'>
              </div>
              <div className='p_course_modify' style={{ display: 'none' }}>
                <div>
                  <div style={{ fontSize: '28px', float: 'left', margin: '20px 20px 0px 30px' }}>课程名:</div>
                  <input className="p_course_modify_name" style={{ width: '885px', marginTop: '25px', marginBottom: '25px', height: '30px' }} />
                </div>
                <div>
                  <div style={{ fontSize: '28px', margin: '20px 30px' }}>课程简介:</div>
                  <textarea className="p_course_modify_jianjie" style={{ marginLeft: '30px', width: '1025px', height: '150px' }} />
                </div>
                <div>
                  <div style={{ fontSize: '28px', margin: '20px 30px' }}>课程封面:</div>
                  <img className="p_course_modify_img1" style={{ border: '1px solid', float: 'left', width: '470px', height: '300px', marginLeft: '30px' }} />
                  <img className="p_course_modify_img2" style={{ border: '1px solid', width: '470px', height: '300px', marginLeft: '80px' }} />
                </div>
                <div>
                  <div style={{ fontSize: '28px', margin: '20px 20px 0px 30px' }}>课程章节:</div>''
                  <div style={{ border: '1px solid', width: '1020px', float: 'left', 'height': '300px', marginLeft: '30px' }}>
                    <div className='p_course_chapters_modify' style={{ float: 'left', border: '1px solid', width: '200px', height: '50px', margin: '10px', borderRadius: '5px', lineHeight: '50px', textAlign: 'center' }}>第一章:第一第一第一</div>
                    <div className='p_course_chapters_add' style={{ float: 'left', border: '1px dashed', width: '200px', height: '50px', margin: '10px', borderRadius: '5px', lineHeight: '50px', textAlign: 'center' }}>新增章節</div>
                  </div>
                </div>
                <div>
                  <div style={{ float: 'left', border: '1px solid', padding: '5px 10px', borderRadius: '5px', margin: '20px 0px 0px 500px' }}>取消修改</div>
                  <div style={{ float: 'left', border: '1px solid', padding: '5px 10px', borderRadius: '5px', margin: '20px 0px 0px 50px', background: '#76b8de', color: '#fff' }}>保存設置</div>
                </div>
              </div>
              <div className="form" style={{ display: 'none' }}>
                {/* <div className="form-window-cover"></div> */}
                <div className="form-window">
                  <div className="form-head"><span className="form-logo"></span>
                    <h3>修改章節</h3><span className="f_cancel">×</span>
                  </div>
                  <div className="form-main">
                    <div className="form-content">
                      <div className="form_delete-main">
                        <div>章節名</div>
                        <input style={{ margin: '2px 0px 0px 10px', width: '320px' }} />
                      </div>
                    </div>
                  </div>
                  <div className="form-b​​tn"><button className="form-confirm">確認</button><button className="form-cencal">取消</button></div>
                </div>
              </div>
            </div>
            {/**房間管理 */}
            <div className="p_table_div">
              <div className="pb_head">
                房间管理<span className="hp_My">&nbsp;&nbsp;Room</span>
                <p>8个房间</p>
              </div>
              <div>
                <div>
                  <div className="room_select">
                    <div className="room_insert" id="room_insert">{language.add_room}</div>
                    <div className="room_select_right">
                      <input type="text" className="room_select_right_input" placeholder={language.room_name} />
                      <button className="room_select_right_button"></button>
                    </div>
                  </div>
                  <div className="room_class">
                  </div>
                  <div className="show_room_page"></div>
                </div>
              </div>
            </div>
            {/**修改密码 */}
            <div className="p_table_div">
              <div>
                <div className="pb_head">修改密码<span className="hp_My">&nbsp;&nbsp;Password</span></div>
                <ul className="edit_password">
                  <li>
                    <span><i>*&nbsp;&nbsp;</i>原密码：</span><input type="password" placeholder="请输入旧密码" onChange={e => {
                      this.setState({
                        old_password: e.target.value
                      })
                    }} />
                  </li>
                  <li>
                    {this.state.border_color ? <p style={{ color: 'red', fontSize: 12, textAlign: 'right' }}>请输入6-20位的英文加数字</p> : ""}
                    <span><i>*&nbsp;&nbsp;</i>新密码：</span><input type="password" style={{ borderColor: this.state.border_color ? 'red' : "" }} placeholder="请输入6-20位的英文加数字" onBlur={(e) => {
                      if (!regpass.test(e.target.value)) {
                        this.setState({
                          border_color: true,
                          new_password: e.target.value
                        })
                      } else {
                        this.setState({
                          border_color: false,
                          new_password: e.target.value
                        })
                      }

                    }} />
                  </li>
                  <li>
                    {this.state.cancle_color ? <p style={{ color: 'red', fontSize: 12, textAlign: 'right' }}>输入与新密码不一样</p> : ""}
                    <span><i>*&nbsp;&nbsp;</i>确认密码：</span><input type="password" placeholder="重新输入新密码" onBlur={e => {
                      if (e.target.value === this.state.new_password) {
                        this.setState({
                          cancle_color: false
                        })
                      } else {
                        this.setState({
                          cancle_color: true
                        })
                      }
                    }} />
                  </li>
                </ul>
                <div style={{ textAlign: 'center', marginTop: 50 }}>
                  <button style={{
                    padding: "10px 30px", background: "#5c98d6", borderRadius: 5, border: 'none'
                    , fontSize: 20, color: '#fff'
                  }} onClick={this.editPassword}>修改密码</button>
                </div>
              </div>
            </div>
            {/**班级管理 */}
            <div className="p_table_div">
              <div>
                {userId && <ClassManage language={language} userId={userId} />}
              </div>
            </div>
            {/**学生列表 */}
            <div className="p_table_div">
              <div>
                {userId && <AddStudent language={language} userId={userId} />}
              </div>
            </div>
            {/**我的课程 */}
            <div className="p_table_div">
              <div>
                {userId && <StudentClass language={language} userId={userId} />}
              </div>
            </div>
            {/**我的作业 */}
            <div className="p_table_div">
              <div>
                {userId && <StudentHomework language={language} userId={userId} />}
              </div>
            </div>

            {/**测验管理 */}
            {/* <div className="p_table_div">
              <div>
                {userId && <TestManage language={language} userId={userId} />}
              </div>
            </div> */}
            {/**编程作业 */}
            {/* <div className="p_table_div">
              <div>
                {userId && <Homework language={language} userId={userId} />}
              </div>
            </div> */}
            {/**好友分组 */}
            <div className="p_table_div" style={{ display: 'none' }}>
              <div className="pb_head">
                好友分组<span className="hp_My">Friend</span>
                <div className="friend_search">
                  <input type="text" className="friend_searchbox" placeholder="搜索"></input>
                  <button className="friend_searchbtn"></button>
                </div>
                <div className="friend_allbtn">
                  <span className="friend_Btn" >删除好友</span>
                  <span className="friend_Btn" >添加好友</span>
                  <span className="friend_Btn" >新建分组</span>
                </div>
              </div>
              <div className="friend_groups">
              </div>
              <div className="friend_people listing">
                <ul className="friend_head listing_head">
                  <li>
                    <div className="friend_first">
                      <input type="checkbox" className="chose-student"></input>
                    </div>
                    <div className="friend_second">学生</div>
                    <div className="friend_third">学校</div>
                    <div className="friend_first">分组</div>
                    <div className="friend_third">邮箱</div>
                    <div className="friend_second">操作</div>
                  </li>
                </ul>
                <ul className="friend_tbody notice_tbody">
                </ul>
              </div>
            </div>

          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </div>
    )
  }
}

export default connect(mapDispatchToProps, mapStateToProps)(profile);

