import React, { Component } from 'react';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import '../css/courseRecordUpload.css'
import CoverUploadPreview from '../components/CoverUploadPreview'
import DemoUploadPreview from '../components/DemoUploadPreview'
import ChapterClipUploadPreview from '../components/ChapterClipUploadPreview'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ChapterInfoUpload from '../components/ChapterInfoUpload';
import { connect } from 'react-redux';
import { Modal } from '@material-ui/core'
import {
    updateCourseCover,
    updateCourseDemo,
    updateChapters
} from '../actions/newupload';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import {ContentUtils} from 'braft-utils'

const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updateCourseCover(cover) {
            dispatch(updateCourseCover(cover))
        },
        updateCourseDemo(demo) {
            dispatch(updateCourseDemo(demo))
        },
        updateChapters(chapters) {
            dispatch(updateChapters(chapters))
        }
    }
}
class CourseUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            steps: 1,
            userId: "",//4be5e344-ce1a-11e9-a839-028edca3b798
            loading: false,
            courseName: "",//课程名称
            courseText: "",//课程描述
            notice: "",//课程公告
            tload: true,
            cover: [],//课程封面
            demo: [],//课程预览视频
            chapterInfo: [{
                isread: false,
                chapterid: this.guid(),
                title: "",
                courseName: "",
                chapterData: [],
                video: [],
                testData: [],
                pData: [],
                editorValue:''
            }],
            chapterIndex: 0,
            open: false,
            open2: false,
            open3: false,
            open4: false,
            testData: {//测验数据
                minutiaName: "", //测试名字
                typeName: "测验",
                time: "", //测试时间
                "status": false,
                "checkout": false,
                contentData: [
                    {
                        titleName: "", //题目
                        radio: ["", "", "", ""],
                        answer: [], //答案
                        answerAnalysis: "", //答案解析
                        type: true
                    }
                ]
            },
            pData: {
                minutiaName: "", //章节名字
                detail: "",
                typeName: "编程",
                "status": false,
                "checkout": false,
            },
            number: "",
            modalShow: false,
            ccc: "",
            updataC: false,
            icc: "",
            unitIndex: 0,
            htmlData:"",
            unitJson: [
                {
                    courseName: "",//课程名称
                    courseText: "",//课程描述
                    notice: "",//课程公告
                    cover: [],//课程封面
                    chapterInfo: [{
                        isread: false,
                        chapterid: this.guid(),
                        title: "",
                        courseName: "",
                        chapterData: [],
                        video: [],
                        testData: [],
                        pData: [],
                        editorValue:''
                    }],
                }
            ],
            testInnerHTML: "例子1[单选题]\nA.答案1\nB.答案2\nC.答案3\nD.答案4\n答案解析:答案解析1\n例子2[多选题]\nA.答案1\nB.答案2\nC.答案3\nD.答案4\n答案解析:答案解析2",
            testInnerJson: [{
                titleName: "例子1", //题目
                radio: ["答案1", "答案2", "答案3", "答案4"],
                answer: [], //答案
                answerAnalysis: "答案解析1", //答案解析
                type: true
            }, {
                titleName: "例子2", //题目
                radio: ["答案1", "答案2", "答案3", "答案4"],
                answer: [], //答案
                answerAnalysis: "答案解析2", //答案解析
                type: false
            }],
            editorValue:[],
            textArray:{}
        }
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.type = "text/javascript";
        script.src = "https://sdk.amazonaws.com/js/aws-sdk-2.235.1.min.js";
        script.async = "async";
        script.onload = function () {
            var credentials = {
                accessKeyId: 'AKIATLPEDU37QV5CHLMH',
                secretAccessKey: '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'
            };  //秘钥形式的登录上传
            window.AWS.config.update(credentials);
            window.AWS.config.region = 'cn-northwest-1';   //设置区域
        }
        document.body.appendChild(script);

        const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
        var _number = window.U.UF.C.queryString("number") || '';
        var _this = this;
        let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
        if (arr) {
            // this.props.history.push('/login')
        } else {
            this.getUserId()
        }

        if (_number) {
            this.setState({
                number: _number
            })
            _this.getCourse(_number)
        }
    }
    getCourse = (number) => {
        axios.get(`${config.env.edu}course2/${number}`).then(res => {
            console.log(res)
            let _courseinfo = res.data[0][0]
            let _unitinfo = res.data[1]
            var _unitJson = []
            let _cover = {
                title: decodeURIComponent(_courseinfo.poster.substr(_courseinfo.poster.lastIndexOf("/")).slice(1)),
                url: _courseinfo.poster,
                style: { backgroundPosition: '-5px -131px', display: "block" }
            }
            this.props.updateCourseCover([_cover]);
            let _demo = {}
            if (_courseinfo.demo) {
                let _demo = {
                    title: decodeURIComponent(_courseinfo.demo.substr(_courseinfo.demo.lastIndexOf("/")).slice(1)),
                    url: _courseinfo.demo,
                    style: { backgroundPosition: '-5px -131px', display: "block" }
                }
                this.props.updateCourseDemo([_demo]);
            }
            // debugger
            for (var i = 0; i < _unitinfo.length; i++) {
                let arr = JSON.parse(_unitinfo[i].chapters)
                for(let c = 0;c<arr.length;c++){
                    let a = arr[c].editorValue
                    let encode = atob(a)
                    arr[c].editorValue = BraftEditor.createEditorState(decodeURI(encode))
                }
                _unitJson.push({
                    courseName: _unitinfo[i].title,//课程名称
                    courseText: JSON.parse(_unitinfo[i].brief).brief,//课程描述
                    notice: JSON.parse(_unitinfo[i].brief).notice,//课程公告
                    cover: [_unitinfo[i].poster],//课程封面
                    chapterInfo: arr,
                })
            }
            // let chapters = JSON.parse(_courseinfo.chapters)
            setTimeout(() => {
                this.setState({
                    courseName: _courseinfo.title,//课程名称
                    courseText: JSON.parse(_courseinfo.brief).brief,//课程描述
                    notice: JSON.parse(_courseinfo.brief).notice,//课程公告
                    cover: [_cover],//课程封面
                    demo: [_demo],//课程预览视频
                    unitJson: _unitJson,
                })
            }, 500);
            console.log(this.state.cover)
            console.log(this.state.demo)
        }).catch(err => console.log(err));
    }
    getUserId = () => {
        var _this = this;
        axios.get(`${config.env.server}getcookieuserid`).then(res => {
            if (res.data[0][0]) {
                _this.setState({
                    userId: res.data[0][0].userid
                });
            }
        }).catch(err => {
            // _this.props.history.push('/login');
            // window.alert(err.response.data)
        })
    }
    guid = () => {
        var _num, i, _guid = "";
        for (i = 0; i < 32; i++) {
            _guid += Math.floor(Math.random() * 16).toString(16); //随机0  - 16 的数字 转变为16进制的字符串
            _num = Math.floor((i - 7) / 4);                        //计算 (i-7)除4
            if (_num > -1 && _num < 4 && (i == (7 + 4 * _num))) {    //会使guid中间加 "-"   形式为xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx
                _guid += "-";
            }
        }
        return _guid;
    }
    nextStep = () => {
        let { steps, courseName, courseText, cover, demo, notice, unitJson, chapterInfo, userId, number } = this.state
        var a = 1;
        if (steps == 1) {
            if (!courseName || !courseText) {
                // || cover.length == 0 || demo.length == 0
                window.U.alert(this.props.language.complete_information)
                return;
            }
        }
        if (steps == 2) {
            for (var i = 0; i < unitJson.length; i++) {
                if (!unitJson[i].courseName || !unitJson[i].notice || !unitJson[i].courseText) {
                    //  || unitJson[i].cover.length == 0
                    window.U.alert(this.props.language.complete_information)
                    return;
                }
            }
        }

        if (steps == 3) {
            for (var i = 0; i < unitJson.length; i++) {
                for (var j = 0; j < unitJson[i].chapterInfo.length; j++) {
                    if (!unitJson[i].chapterInfo[j].title) {
                        window.U.alert("请填写章节名称！")
                        return;
                    }
                }
            }
        }
        if (steps == 4) {
            if (!courseName || !courseText) {
                //  || cover.length == 0 || demo.length == 0
                window.U.alert(this.props.language.complete_information)
                return;
            }
            for (var i = 0; i < unitJson.length; i++) {
                if (!unitJson[i].courseName || !unitJson[i].notice || !unitJson[i].courseText) {
                    // || unitJson[i].cover.length == 0
                    window.U.alert(this.props.language.complete_information)
                    return;
                }
            }
            for (var i = 0; i < unitJson.length; i++) {
                for (var j = 0; j < unitJson[i].chapterInfo.length; j++) {
                    if (!unitJson[i].chapterInfo[j].title || unitJson[i].chapterInfo[j].chapterData.length == 0) {
                        window.U.alert(this.props.language.complete_information)
                        return;
                    }
                }
            }
            // for (let i = 0; i < chapterInfo.length; i++) {
            //     if (!chapterInfo[i].title || !chapterInfo[i].courseName || chapterInfo[i].chapterData.length == 0) {
            //         window.alert(this.props.language.complete_information)
            //         return;
            //     }
            // }
            a = 2
        }
        if (steps == 4 && a == 2) {
            this.setState({
                modalShow: true,
                ccc: "上传中..."
            })
            // var encodetitle = encodeURIComponent(encodeURIComponent(courseName))
            // var encodebrief = encodeURIComponent(encodeURIComponent(JSON.stringify({ brief: courseText, notice })))
            // var encodedemo = encodeURIComponent(encodeURIComponent(demo[0].url))
            // var encodecover = encodeURIComponent(encodeURIComponent(cover[0].url))
            var unitJsonA = unitJson
            for (let c = 0; c < unitJsonA.length; c++) {
                unitJsonA[c].cover[0] = unitJsonA[c].cover[0] ? unitJsonA[c].cover[0] : "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/demo20210907160645656165016.png"
                // chapterInfoA[0].chapterData[0].checkout = true
                for (var i = 0; i < unitJsonA[c].chapterInfo.length; i++) {
                    delete unitJsonA[c].chapterInfo[i].video
                    delete unitJsonA[c].chapterInfo[i].testData
                    delete unitJsonA[c].chapterInfo[i].pData
                    let encode = encodeURI(unitJsonA[c].chapterInfo[i].editorValue.toHTML())
                    let base64 = btoa(encode)
                    unitJsonA[c].chapterInfo[i].editorValue = base64
                    for (var j = 0; j < unitJsonA[c].chapterInfo[i].chapterData.length; j++) {
                        unitJsonA[c].chapterInfo[i].chapterData[j].iid = j
                    }
                }
            }
            // var encodechapters = encodeURIComponent(encodeURIComponent(JSON.stringify(chapterInfoA)))
            // console.log(`mode=${encodetitle},${encodebrief},${encodedemo},${encodecover},${encodechapters}`);
            if (number) {
                let params = {
                    userId,
                    number,
                    courseName,
                    brief: JSON.stringify({ brief: courseText, notice }),
                    demo: demo[0] ? demo[0].url : "",
                    cover: cover[0] ? cover[0].url : "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/demo20210907160645656165016.png",
                    unitJson: JSON.stringify(unitJsonA)
                }
                // var a = "http://localhost:7001/edu/" // config.env.edu
                axios.post(`${config.env.edu}updateCourses2`, qs.stringify(params)
                ).then((res => {
                    if (res.status === 200) {
                        this.setState({
                            modalShow: false,
                            ccc: "上传成功"
                        })
                        // 修改成功清空数据
                        this.setState({
                            steps: steps + 1,
                        })
                    }
                })).catch((err) => {
                    window.U.alert(this.props.language.change_failure + ',error:' + err)
                })
            } else {
                let params = {
                    userId,
                    courseName,
                    brief: JSON.stringify({ brief: courseText, notice }),
                    demo: demo[0] ? demo[0].url : "",
                    cover: cover[0] ? cover[0].url : "https://ccrb.s3.cn-northwest-1.amazonaws.com.cn/demo20210907160645656165016.png",
                    unitJson: JSON.stringify(unitJsonA)
                }
                // var a = "http://localhost:7001/edu/" // config.env.edu
                axios.post(`${config.env.edu}createRecordCourses2`, qs.stringify(params)
                ).then((res => {
                    if (res.status === 200) {
                        this.setState({
                            modalShow: false,
                            ccc: "上传成功"
                        })
                        // 上传成功清空数据
                        this.setState({
                            steps: steps + 1,
                            number: res.data[0][0].number
                        })
                    }
                })).catch((err) => {
                    window.U.alert(this.props.language.upload_fail + ',error:' + err)
                })
            }
        } else {
            this.setState({
                steps: steps + 1,
                unitIndex: 0,
                chapterIndex: 0,
            })
        }

    }
    redirectToCourse = () => {
        window.location.href = `${config.env.basename}/course/${this.state.number}`
    }
    lastStep = () => {
        let { steps } = this.state
        this.setState({
            steps: steps - 1,
            unitIndex: 0,
            chapterIndex: 0,
        })
    }
    getCoverVal = (val) => {
        this.setState({
            cover: val
        })
    }
    getDemoVal = (val) => {
        this.setState({
            demo: val
        })
    }
    updatetitleVal = (e) => {
        this.setState({
            courseName: e.target.value
        })
    }
    updateTextVal = (e) => {
        this.setState({
            courseText: e.target.value
        })
    }
    updateNoticeVal = (e) => {
        this.setState({
            notice: e.target.value
        })
    }
    addChapter = (j) => {
        let { unitJson } = this.state
        unitJson[j].chapterInfo.push({
            isread: false,
            chapterid: this.guid(),
            title: "",
            courseName: "",
            chapterData: [],
            video: [],
            testData: [],
            pData: [],
            editorValue:''
        })
        this.setState({
            unitJson: unitJson
        })
    }
    addunit = () => {
        let { unitJson } = this.state
        unitJson.push({
            courseName: "",//课程名称
            courseText: "",//课程描述
            notice: "",//课程公告
            cover: [],//课程封面
            chapterInfo: [{
                isread: false,
                chapterid: this.guid(),
                title: "",
                courseName: "",
                chapterData: [],
                video: [],
                testData: [],
                pData: [],
                editorValue:''
            }],
        })
        this.setState({
            unitJson: unitJson
        })
    }
    updateChapterTitleVal = (e, j, i) => {
        let { unitJson } = this.state
        unitJson[j].chapterInfo[i].title = e.target.value
        this.setState({
            unitJson: unitJson
        })
    }

    updateUnitVal = (e, i) => {
        let { unitJson } = this.state
        unitJson[i].courseName = e.target.value
        this.setState({
            unitJson: unitJson
        })
    }

    updateUnitTextVal = (e, i) => {
        let { unitJson } = this.state
        unitJson[i].courseText = e.target.value
        this.setState({
            unitJson: unitJson
        })
    }
    updateUnitNoticeVal = (e, i) => {
        let { unitJson } = this.state
        unitJson[i].notice = e.target.value
        this.setState({
            unitJson: unitJson
        })
    }

    deleteChapter = (j, i) => {
        let { unitJson } = this.state
        var _this = this
        window.U.UF.UI.confirm("确定删除此章节吗?", function () {
            unitJson[j].chapterInfo.splice(i, 1)
            _this.setState({
                unitJson: unitJson
            })
        }, function () {
            return;
        })

    }
    deleteUnit = (i) => {
        let { unitJson, unitIndex } = this.state
        var _this = this
        window.U.UF.UI.confirm("确定删除此单元吗?", function () {
            unitJson.splice(i, 1)
            var a = 0;
            if (unitIndex > 0) {
                a = unitIndex - 1
            }
            _this.setState({
                unitJson: unitJson,
                unitIndex: a
            })
        }, function () {
            return;
        })
    }
    updatechapterVideo = (v, i) => {
        let { chapterInfo } = this.state
        chapterInfo[i].chapterData = []
        chapterInfo[i].video = []
        for (let c = 0; c < v.length; c++) {
            chapterInfo[i].chapterData.push({
                "src": v[c].url, //视频地址
                "status": false,
                "minutiaName": v[c].title, //视频名字
                "typeName": "视频",
                "checkout": false
            })
            chapterInfo[i].video.push(v[c])
        }
        this.setState({
            chapterInfo: chapterInfo,
        })
    }

    updateChapterCourseNameVal = (e, i, j) => {
        let { unitJson } = this.state
        unitJson[i].chapterInfo[j].courseName = e.target.value
        this.setState({
            unitJson: unitJson
        })
    }

    lastChapter = () => {
        let { chapterIndex } = this.state
        this.setState({
            chapterIndex: chapterIndex - 1,
            tload: false
        })
        setTimeout(() => {
            this.setState({
                tload: true
            })
        }, 1);
    }

    nextChapter = () => {
        let { chapterIndex } = this.state
        this.setState({
            chapterIndex: chapterIndex + 1,
            tload: false
        })
        setTimeout(() => {
            this.setState({
                tload: true
            })
        }, 1);
    }

    handleClickOpen = (i, j, ic) => {
        let { unitJson } = this.state
        var testData;
        if (ic || ic == 0) {
            testData = unitJson[i].chapterInfo[j].chapterData[ic]
        } else {
            this.setState({
                updataC: false
            })
            testData = {//测验数据
                id: this.guid(),
                minutiaName: "", //测试名字
                typeName: "测验",
                time: "", //测试时间
                "status": false,
                "checkout": false,
                contentData: [
                    {
                        titleName: "", //题目
                        radio: ["", "", "", ""],
                        answer: [], //答案
                        answerAnalysis: "", //答案解析
                        type: true
                    }
                ]


            }
        }
        this.setState({
            open: true,
            testData: testData,
            chapterIndex: j,
            unitIndex: i
        })
    };
    deleteUnitImg = (i) => {
        let { unitJson } = this.state
        unitJson[i].cover = []
        this.setState({
            unitJson: unitJson,
        })
    }
    deleteChapterTest = (i) => {
        let { chapterInfo } = this.state
        chapterInfo[i].testData = []
        this.setState({
            chapterInfo: chapterInfo,
        })
    }

    handleClickPOpen = (i, j, ic) => {
        let { unitJson } = this.state
        var pData;
        if (ic || ic == 0) {
            pData = unitJson[i].chapterInfo[j].chapterData[ic]
        } else {
            pData = {//测验数据
                id: this.guid(),
                minutiaName: "", //测试名字
                detail: "",
                typeName: "编程",
                "status": false,
                "checkout": false,
            }
            this.setState({
                updataC: false
            })
        }

        this.setState({
            open2: true,
            pData: pData,
            chapterIndex: j,
            unitIndex: i
        })
    };

    handleClickHtmlOpen = (i, j, ic) => {
        let { unitJson } = this.state
        var htmlData;
        if (ic || ic == 0) {
            htmlData = unitJson[i].chapterInfo[j].chapterData[ic]
        } else {
            htmlData = {//测验数据
                id: this.guid(),
                minutiaName: "", //测试名字
                typeName: "网页",
                "status": false,
                "checkout": false,
            }
            this.setState({
                updataC: false
            })
        }

        this.setState({
            open4: true,
            htmlData: htmlData,
            chapterIndex: j,
            unitIndex: i
        })
    };

    deleteChapterP = (i) => {
        let { chapterInfo } = this.state
        chapterInfo[i].pData = []
        this.setState({
            chapterInfo: chapterInfo,
        })
    }
    handleClose = () => {
        this.setState({
            open: false
        })
    };
    handleClose2 = () => {
        this.setState({
            open2: false
        })
    };
    handleClose4 = () => {
        this.setState({
            open4: false
        })
    };
    handleClose3 = () => {
        this.setState({
            open3: false
        })
    };
    confirmTest = () => {
        let { unitJson, unitIndex, chapterIndex, testData, updataC, icc } = this.state
        if (testData.minutiaName == "") {
            window.U.alert("请填写测试名称")
            return
        }
        // if (testData.time == "") {
        // testData.time = 0
        // window.U.alert("请填写测试时间")
        // return
        // }
        for (let i = 0; i < testData.contentData.length; i++) {
            var a = testData.contentData[i]
            if (a.titleName == "" || (a.radio.indexOf("") != -1 && a.type !== 1)) {
                window.U.alert(this.props.language.complete_information)
                return
            } else if (a.answer.length == 0 || a.answer[0] === "") {
                window.U.alert("第" + (i + 1) + "题请选择正确答案")
                return
            }
        }
        // if (chapterInfo[chapterIndex].testData) {
        //     chapterInfo[chapterIndex].testData[0] = testData
        // } else {
        //     chapterInfo[chapterIndex].testData = [testData]
        // }
        if (updataC) {
            unitJson[unitIndex].chapterInfo[chapterIndex].chapterData[icc] = testData
        } else {
            unitJson[unitIndex].chapterInfo[chapterIndex].chapterData.push(testData)
        }
        window.U.alert("添加成功")
        this.setState({
            open: false,
            unitJson: unitJson,
            updataC: false,
            icc: ""
        })
    }
    confirmP = () => {
        let { unitJson, unitIndex, chapterIndex, pData, updataC, icc } = this.state
        if (pData.minutiaName == "") {
            window.U.alert("请填写编程标题")
            return
        }
        if (pData.detail == "") {
            window.U.alert("请填写编程描述")
            return
        }
        // if (chapterInfo[chapterIndex].pData) {
        //     chapterInfo[chapterIndex].pData[0] = pData
        // } else {
        //     chapterInfo[chapterIndex].pData = [pData]
        // }
        if (updataC) {
            unitJson[unitIndex].chapterInfo[chapterIndex].chapterData[icc] = pData
        } else {
            unitJson[unitIndex].chapterInfo[chapterIndex].chapterData.push(pData)
        }

        this.setState({
            open2: false,
            unitJson: unitJson,
            updataC: false,
            icc: ""
        })
    }
    confirmHtml = () => {
        let { unitJson, unitIndex, chapterIndex, htmlData, updataC, icc } = this.state
        if (htmlData.minutiaName == "") {
            window.U.alert("请填写网页链接")
            return
        }
        if (updataC) {
            unitJson[unitIndex].chapterInfo[chapterIndex].chapterData[icc] = htmlData
        } else {
            unitJson[unitIndex].chapterInfo[chapterIndex].chapterData.push(htmlData)
        }

        this.setState({
            open4: false,
            unitJson: unitJson,
            updataC: false,
            icc: ""
        })
    }
    updateTestTitleVal = (e) => {
        let { testData } = this.state
        testData.minutiaName = e.target.value
        this.setState({
            testData: testData
        })
    }
    updatePTitleVal = (e) => {
        let { pData } = this.state
        pData.minutiaName = e.target.value
        this.setState({
            pData: pData
        })
    }
    updateHtmlTitleVal = (e) => {
        let { htmlData } = this.state
        htmlData.minutiaName = e.target.value
        this.setState({
            htmlData: htmlData
        })
    }

    updatePDetailVal = (e) => {
        let { pData } = this.state
        pData.detail = e.target.value
        this.setState({
            pData: pData
        })
    }

    updatetestInner = (e) => {
        var json = [];
        // testInnerJson: [{
        //     titleName: "例子1", //题目
        //     radio: ["答案1", "答案2", "答案3", "答案4"],
        //     answer: [], //答案
        //     answerAnalysis: "答案解析1", //答案解析
        //     type: true
        // }, {
        //     titleName: "例子2", //题目
        //     radio: ["答案1", "答案2", "答案3", "答案4"],
        //     answer: [], //答案
        //     answerAnalysis: "答案解析2", //答案解析
        //     type: false
        // },
        // {
        //     titleName: "例子3", //题目
        //     radio: ["", "", "", ""],
        //     answer: ["答案1"], //答案
        //     answerAnalysis: "答案解析3", //答案解析
        //     type: 1
        // }]
        var tests = e.target.value.split("\n")
        var test = {
            titleName: "", //题目
            radio: ["", "", "", ""],
            answer: [], //答案
            answerAnalysis: "", //答案解析
            type: true
        }
        for (let i = 0; i < tests.length; i++) {
            if (i == 0 || (i + 6) % 6 == 0) {
                if (tests[i].indexOf("[单选题]") != -1) {
                    test.type = true
                    test.titleName = tests[i].split("[单选题]")[0]
                } else if (tests[i].indexOf("[多选题]") != -1) {
                    test.type = false
                    test.titleName = tests[i].split("[多选题]")[0]
                } else {
                    test.titleName = tests[i]
                }
            }
            if (i == 1 || ((i + 5) % 6 == 0 && i != 0)) {
                test.radio[0] = tests[i].split("A.")[1]
            }
            if (i == 2 || ((i + 4) % 6 == 0 && i != 0)) {
                test.radio[1] = tests[i].split("B.")[1]
            }
            if (i == 3 || ((i + 3) % 6 == 0 && i != 0)) {
                test.radio[2] = tests[i].split("C.")[1]
            }
            if (i == 4 || ((i + 2) % 6 == 0 && i != 0)) {
                test.radio[3] = tests[i].split("D.")[1]
            }
            if (i == 5 || ((i + 1) % 6 == 0 && i != 0)) {
                test.answerAnalysis = tests[i].split("答案解析:")[1]
            }
            json[Math.ceil((i + 1) / 6) - 1] = test
            if (i == 5 || ((i + 1) % 6 == 0 && i != 0)) {
                test = {
                    titleName: "", //题目
                    radio: ["", "", "", ""],
                    answer: [], //答案
                    answerAnalysis: "", //答案解析
                    type: true
                }
            }
        }
        this.setState({
            testInnerHTML: e.target.value,
            testInnerJson: json
        })
    }

    emptyText = () => {
        this.setState({
            testInnerHTML: "",
            testInnerJson: []
        })
    }

    updateTestVal = (e, i) => {
        let { testData } = this.state
        testData.contentData[i].titleName = e.target.value
        this.setState({
            testData: testData
        })
    }

    updateRadioVal = (e, i, j, checked) => {
        let { testData } = this.state
        // if (testData.contentData[i].answer.indexOf(checked) != -1) {
        //     var index = testData.contentData[i].answer.indexOf(checked)
        //     if (e.target.value == "") {
        //         testData.contentData[i].answer.splice(index, 1)
        //     } else {
        //         testData.contentData[i].answer[index] = e.target.value
        //     }
        // }
        testData.contentData[i].radio[j] = e.target.value
        this.setState({
            testData: testData
        })
    }

    updateAnswerVal = (e, i) => {
        let { testData } = this.state
        testData.contentData[i].answerAnalysis = e.target.value
        this.setState({
            testData: testData
        })
    }

    updateTimeVal = (e) => {
        let { testData } = this.state
        testData.time = e.target.value
        this.setState({
            testData: testData
        })
    }

    addTestsData = () => {
        let { testData, testInnerJson } = this.state
        testData.contentData.push(...testInnerJson)
        this.setState({
            testData: testData,
            open3: false
        })
    }

    addTest = () => {
        let { testData } = this.state
        testData.contentData.push({
            titleName: "", //题目
            radio: ["", "", "", ""],
            answer: [], //答案
            type: true,
            answerAnalysis: "" //答案解析
        })
        this.setState({
            testData: testData
        })
    }

    addTests = () => {
        this.setState({
            open3: true,
            testInnerHTML: "例子1[单选题]\nA.答案1\nB.答案2\nC.答案3\nD.答案4\n答案解析:答案解析1\n例子2[多选题]\nA.答案1\nB.答案2\nC.答案3\nD.答案4\n答案解析:答案解析2",
            testInnerJson: [{
                titleName: "例子1", //题目
                radio: ["答案1", "答案2", "答案3", "答案4"],
                answer: [], //答案
                answerAnalysis: "答案解析1", //答案解析
                type: true
            }, {
                titleName: "例子2", //题目
                radio: ["答案1", "答案2", "答案3", "答案4"],
                answer: [], //答案
                answerAnalysis: "答案解析2", //答案解析
                type: false
            }]
        })
    }

    deleteTest = (i) => {
        let { testData } = this.state
        testData.contentData.splice(i, 1)
        this.setState({
            testData: testData
        })
    }

    addRadio = (i) => {
        let { testData } = this.state
        testData.contentData[i].radio.push("")
        this.setState({
            testData: testData
        })
    }
    deleteRadio = (i) => {
        let { testData } = this.state
        testData.contentData[i].radio.splice(testData.contentData[i].radio.length - 1, 1)
        this.setState({
            testData: testData
        })
    }

    checkAnswer = (i, checked, e) => {
        // if (checked == "") {
        //     return;
        // }
        let { testData } = this.state
        if (testData.contentData[i].type === true) {
            testData.contentData[i].answer = []
            testData.contentData[i].answer.push(checked)
        } else if (testData.contentData[i].type === 1) {
            testData.contentData[i].answer = []
            testData.contentData[i].answer.push(e.target.value)
        } else {
            if (testData.contentData[i].answer.indexOf(checked) != -1) {
                testData.contentData[i].answer = testData.contentData[i].answer.filter(v => { return v != checked })
            } else {
                testData.contentData[i].answer.push(checked)
            }
            testData.contentData[i].answer = testData.contentData[i].answer.sort()
        }

        this.setState({
            testData: testData
        })
    }
    checkType = (i, type) => {
        let { testData } = this.state
        testData.contentData[i].type = type
        testData.contentData[i].answer = []
        this.setState({
            testData: testData
        })
    }
    add = function (e, i) {
        var el = e.currentTarget
        el.getElementsByTagName("input")[0].click();
        // this.setState({
        //     chapterIndex: i
        // })
    }

    addImg = function (e, i) {
        var el = e.currentTarget
        el.getElementsByTagName("input")[0].click();
    }

    upload = function (e, i, j) {
        const { unitJson, chapterIndex } = this.state

        var input = e.currentTarget;
        this.setState({
            modalShow: true,
            ccc: "上传中..."
        })
        if (input.files.length !== 0) {

            // create bucket instance
            var bucket = new window.AWS.S3({ params: { Bucket: 'ccrb' } });  //选择桶
            var file = input.files[0];
            var _this = this;

            if (file) {
                var _uploadid = (window.localStorage['uploadid'] || "");
                var params = { Key: file.name, ContentType: file.type, Body: file, 'Access-Control-Allow-Credentials': '*', 'ACL': 'public-read' }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
                var options = { partSize: 2048 * 1024 * 1024, queueSize: 2, leavePartsOnError: true };
                bucket.upload(params, options).
                    on('httpUploadProgress', function (evt) {
                    }).send(function (err, data) {
                        if (err) {
                            _this.setState({
                                ccc: "上传失败"
                            })
                            setTimeout(() => {
                                _this.setState({
                                    modalShow: false
                                })
                            }, 2000);
                        } else {
                            _this.setState({
                                ccc: "上传成功"
                            })
                            setTimeout(() => {
                                _this.setState({
                                    modalShow: false
                                })
                            }, 2000);
                            unitJson[i].chapterInfo[j].chapterData.push({
                                "src": data.Location, //视频地址
                                "status": false,
                                "minutiaName": data.Key, //视频名字
                                "typeName": "视频",
                                "checkout": false
                            })
                            _this.setState({
                                unitJson: unitJson
                            })
                            console.log(data.Location);
                            console.log(data);
                        }
                    });
                input.value = "";
            }
        }
    }

    upload2 = function (e, i, j) {
        const { unitJson, chapterIndex } = this.state

        var input = e.currentTarget;
        this.setState({
            modalShow: true,
            ccc: "上传中..."
        })
        if (input.files.length !== 0) {

            // create bucket instance
            var bucket = new window.AWS.S3({ params: { Bucket: 'ccrb' } });  //选择桶
            var file = input.files[0];
            var _this = this;

            if (file) {
                var _uploadid = (window.localStorage['uploadid'] || "");
                var params = { Key: file.name, ContentType: file.type, Body: file, 'Access-Control-Allow-Credentials': '*', 'ACL': 'public-read' }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
                var options = { partSize: 2048 * 1024 * 1024, queueSize: 2, leavePartsOnError: true };
                bucket.upload(params, options).
                    on('httpUploadProgress', function (evt) {
                    }).send(function (err, data) {
                        if (err) {
                            _this.setState({
                                ccc: "上传失败"
                            })
                            setTimeout(() => {
                                _this.setState({
                                    modalShow: false
                                })
                            }, 2000);
                        } else {
                            _this.setState({
                                ccc: "上传成功"
                            })
                            setTimeout(() => {
                                _this.setState({
                                    modalShow: false
                                })
                            }, 2000);
                            var _type = data.Key.split(".")[data.Key.split(".").length - 1]
                            if (_type == "pdf") {
                                unitJson[i].chapterInfo[j].chapterData.push({
                                    "src": data.Location, //pdf地址
                                    "status": false,
                                    "minutiaName": data.Key, //pdf名字
                                    "typeName": "PDF",
                                    "checkout": false
                                })
                            } else if (_type == "doc" || _type == "docx") {
                                unitJson[i].chapterInfo[j].chapterData.push({
                                    "src": data.Location,
                                    "status": false,
                                    "minutiaName": data.Key,
                                    "typeName": "DOC",
                                    "checkout": false
                                })
                            } else if (_type == "ppt" || _type == "pptx") {
                                unitJson[i].chapterInfo[j].chapterData.push({
                                    "src": data.Location,
                                    "status": false,
                                    "minutiaName": data.Key,
                                    "typeName": "PPT",
                                    "checkout": false
                                })
                            }
                            _this.setState({
                                unitJson: unitJson
                            })
                            console.log(data.Location);
                            console.log(data);
                        }
                    });
                input.value = "";
            }
        }
    }

    uploadImg = function (e, i) {
        let { unitJson } = this.state

        var input = e.currentTarget;
        this.setState({
            modalShow: true,
            ccc: "上传中..."
        })
        if (input.files.length !== 0) {

            // create bucket instance
            var bucket = new window.AWS.S3({ params: { Bucket: 'ccrb' } });  //选择桶
            var file = input.files[0];
            var _this = this;

            if (file) {
                var _uploadid = (window.localStorage['uploadid'] || "");
                var params = { Key: file.name, ContentType: file.type, Body: file, 'Access-Control-Allow-Credentials': '*', 'ACL': 'public-read' }; //key可以设置为桶的相抵路径，Body为文件， ACL最好要设置
                var options = { partSize: 2048 * 1024 * 1024, queueSize: 2, leavePartsOnError: true };
                bucket.upload(params, options).
                    on('httpUploadProgress', function (evt) {
                    }).send(function (err, data) {
                        if (err) {
                            _this.setState({
                                ccc: "上传失败"
                            })
                            setTimeout(() => {
                                _this.setState({
                                    modalShow: false
                                })
                            }, 2000);
                        } else {
                            _this.setState({
                                ccc: "上传成功"
                            })
                            setTimeout(() => {
                                _this.setState({
                                    modalShow: false
                                })
                            }, 2000);
                            unitJson[i].cover.push(data.Location);
                            _this.setState({
                                unitJson: unitJson
                            })
                            console.log(data.Location);
                            console.log(data);
                        }
                    });
                input.value = "";
            }
        }
    }

    openImg = (i) => {
        const { unitJson } = this.state
        window.open(unitJson[i].cover[0])
    }

    deleteChapterData = (e, i, j, ic) => {
        window.U.UF.EV.stopBubble();
        e.stopPropagation();
        let { unitJson } = this.state
        unitJson[i].chapterInfo[j].chapterData.splice(ic, 1)
        this.setState({
            unitJson: unitJson
        })
    }

    getChapterData = (e, i, j, ic, typeName) => {
        e.stopPropagation();
        this.setState({
            updataC: true,
            icc: ic
        })
        let { unitJson } = this.state
        if ((typeName == "视频" || typeName == "PDF") && e.target.tagName !== 'INPUT') {
            window.open(unitJson[i].chapterInfo[j].chapterData[ic].src)
        } else if (typeName == "测验") {
            this.handleClickOpen(i, j, ic)
        } else if (typeName == "编程") {
            this.handleClickPOpen(i, j, ic)
        } else if (typeName == "网页") {
            this.handleClickHtmlOpen(i, j, ic)
        } else if ((typeName == "PPT" || typeName == "DOC") && e.target.tagName !== 'INPUT') {
            window.open("http://view.officeapps.live.com/op/view.aspx?src=" + unitJson[i].chapterInfo[j].chapterData[ic].src)
        }
    }

    updataVideoT = (e, i, j, ic) => {
        e.stopPropagation();
        let { unitJson } = this.state
        unitJson[i].chapterInfo[j].chapterData[ic].minutiaName = e.target.value
        this.setState({
            unitJson: unitJson
        })
    }
    upCd = (e, i, j, ic) => {
        e.stopPropagation();
        let { unitJson } = this.state
        if (ic == 0) {
            return;
        }
        var a = unitJson[i].chapterInfo[j].chapterData[ic - 1]
        unitJson[i].chapterInfo[j].chapterData[ic - 1] = unitJson[i].chapterInfo[j].chapterData[ic]
        unitJson[i].chapterInfo[j].chapterData[ic] = a
        this.setState({
            unitJson: unitJson
        })
    }
    downCd = (e, i, j, ic) => {
        e.stopPropagation();
        let { unitJson } = this.state
        if (ic == unitJson[i].chapterInfo[j].chapterData.length - 1) {
            return;
        }
        var a = unitJson[i].chapterInfo[j].chapterData[ic + 1]
        unitJson[i].chapterInfo[j].chapterData[ic + 1] = unitJson[i].chapterInfo[j].chapterData[ic]
        unitJson[i].chapterInfo[j].chapterData[ic] = a
        this.setState({
            unitJson: unitJson
        })
    }
    clearContent=()=>{
        console.log('清空编辑器');
        this.setState({
            editorValue:ContentUtils.clear(this.state.editorValue,'hello world')
        })
    }
    inserText=()=>{
        this.setState({
            editorValue:ContentUtils.inserText(this.state.editorValue,'hello world')
        })
    }
    editorChange=(value,index,j)=>{
        // console.log(value.toHTML(),index,j)
        const {unitJson} = this.state
        unitJson[index].chapterInfo[j].editorValue = value
        this.setState({unitJson})
    }

    render() {
        // const extendControls = [
        //     {key:'clear-deitor',type:'button',text:'清空编辑器',onClick:this.clearContent},
        //     // {key:'inser-text',type:'button',text:'插入自定义文本',onClick:this.inserText}
        // ];
        const { steps, courseName, courseText, notice, chapterInfo, chapterIndex, open, testData,editorValue, tload, number, open2, open3, open4, pData, htmlData, modalShow, ccc, unitJson, unitIndex, testInnerHTML, testInnerJson } = this.state
        const { language } = this.props
        return (
            <div className="info_box">
                <div className="info_left">
                    <div className="info_tips">请跟随下面的步骤,逐步上传你的课程或房间內容:</div>
                    <div className="info_steps">
                        <span className={steps > 0 ? "steps_active" : ""}>{steps > 1 ? "√" : "1"}</span><span>填写课程基本信息</span>
                    </div>
                    <div className="info_solid"></div>
                    <div className="info_steps">
                        <span className={steps > 1 ? "steps_active" : ""}>{steps > 2 ? "√" : "2"}</span><span>上传课程单元内容</span>
                    </div>
                    <div className="info_solid"></div>
                    <div className="info_steps">
                        <span className={steps > 2 ? "steps_active" : ""}>{steps > 3 ? "√" : "3"}</span><span>上传课程章节内容</span>
                    </div>
                    <div className="info_solid"></div>
                    <div className="info_steps">
                        <span className={steps > 3 ? "steps_active" : ""}>{steps == 5 ? "√" : "4"}</span><span>课程内容填写</span>
                    </div>
                    <div className="info_solid"></div>
                    <div className="info_steps">
                        <span className={steps > 4 ? "steps_active" : ""}>{steps == 5 ? "√" : "5"}</span><span>上传完成</span>
                    </div>
                </div>
                <div className="info_right">
                    {steps == 1 ? <div className="basic_info_box">
                        <h3 className="Info_title">填写基本信息</h3>
                        <div>
                            <div className="bInfo_title"><span style={{ color: "red" }}>*</span>课程名称</div>
                            <div><input type="text" placeholder="课程名称" className="binfo_input" value={courseName} onChange={v => this.updatetitleVal(v)} /></div>
                        </div>
                        <div>
                            <div className="bInfo_title"><span style={{ color: "red" }}>*</span>课程描述</div>
                            <div><textarea rows="4" placeholder="课程描述" className="binfo_input" cols="" value={courseText} onChange={v => this.updateTextVal(v)}></textarea></div>
                        </div>
                        {/* <div>
                            <div className="bInfo_title"><span style={{ color: "red" }}>*</span>公告</div>
                            <div><input type="text" placeholder="公告" className="binfo_input" value={notice} onChange={v => this.updateNoticeVal(v)} /></div>
                        </div> */}
                        <div css={`display:flex`}>
                            <div css={`flex: .5;margin: 0 20px 0 0;`}>
                                <div className="bInfo_title">
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                    课程封面
                                    <div className="chapter_beizhu">
                                        PNG, GIF, JPEG
                                    </div>
                                </div>
                                <CoverUploadPreview
                                    getCoverVal={this.getCoverVal}
                                    language={language}
                                />
                            </div>
                            <div css={`flex: .5;`}>
                                <div className="bInfo_title">
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                    课程预览视频
                                    <div className="chapter_beizhu">
                                        MP4, M4V, MOV, AVI
                                    </div>
                                </div>
                                <DemoUploadPreview
                                    getDemoVal={this.getDemoVal}
                                    language={language}
                                />
                            </div>
                        </div>
                        <hr className="info_line" />
                    </div> : ""}
                    {steps == 2 ? <div div className="basic_info_box">
                        <h3 className="Info_title">上传课程单元内容:单元目录信息填写</h3>
                        <div className="cru_selectBox">
                            {unitJson.length > 0 && unitJson.map((v, i) => (
                                <div className={"cru_select " + (unitIndex == i ? "cru_selected" : "")} onClick={() => { this.setState({ unitIndex: i, chapterIndex: 0 }) }}>第{i + 1}单元</div>
                            ))}
                            <img src={require('../assets/img/manage/line.png')} className={`cru_line`} style={{ left: (window.$(".cru_select")[unitIndex] && window.$(".cru_select")[unitIndex].offsetLeft + "px") }} />
                        </div>
                        <div className="chapter_box" style={{ height: "345px", maxHeight: "500px" }}>
                            <div>
                                <div className="chapter_contentbox">
                                    <div>
                                        第{unitIndex + 1}单元
                                    </div>
                                    <div>
                                        <input type="text" placeholder="单元标题" className="binfo_input" value={unitJson[unitIndex].courseName} onChange={(e) => this.updateUnitVal(e, unitIndex)} />
                                    </div>
                                    {
                                        unitJson.length > 1 ? <div onClick={() => this.deleteUnit(unitIndex)}></div> : ""
                                    }
                                </div>
                                <div css={`display:flex;`}>
                                    <div css={`flex: .35;margin: 0 30px 0 0;`}>
                                        <div className="bInfo_title">
                                            {/* <span style={{ color: "red" }}>*</span> */}
                                            单元封面
                                            <div className="chapter_beizhu">
                                                PNG, GIF, JPEG
                                            </div>
                                        </div>
                                        <div className="chapter_uploadBox1">
                                            {unitJson[unitIndex].cover.length == 0 ? <div className="chapter_add" onClick={(e) => this.addImg(e, unitIndex)}>
                                                <div className="chapter_add_l">+</div>
                                                <div className="chapter_add_r">添加文件</div>
                                                <input type="file"
                                                    accept="image/png,image/gif,image/jpeg,"
                                                    style={{ display: "none" }}
                                                    onChange={(e) => this.uploadImg(e, unitIndex)} />
                                            </div> :
                                                <div className="chapter_upload_move" style={{ height: "100%" }}>
                                                    <div className="chapter_upload_t" style={{ width: "100%" }}></div>
                                                    <div className="chapter_upload_o" style={{ top: "7px" }}>
                                                        <div className="chapter_upload_l" onClick={() => this.openImg(unitIndex)}>
                                                            <div className="chapter_upload_l_i"></div>
                                                        </div>
                                                        <div className="chapter_upload_ic">
                                                            <div className="chapter_upload_ic_l" onClick={() => this.openImg(unitIndex)}>
                                                                <div style={{ backgroundPosition: "-5px -131px", display: "block" }}>
                                                                </div></div>
                                                            <div className="chapter_upload_ic_r" style={{ cursor: "pointer" }}>
                                                                <div onClick={() => this.deleteUnitImg(unitIndex)}>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chapter_upload_n" onClick={() => this.openImg(unitIndex)}>{unitJson[unitIndex].cover[0].substr(unitJson[unitIndex].cover[0].lastIndexOf("/")).slice(1)}</div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </div>
                                    <div css={`flex: .35;margin: 0 10px 0 0;`}>
                                        <div className="bInfo_title"><span style={{ color: "red" }}>*</span>公告</div>
                                        <div><input type="text" placeholder="公告" className="binfo_input" value={unitJson[unitIndex].notice} onChange={v => this.updateUnitNoticeVal(v, unitIndex)} /></div>
                                    </div>
                                </div>
                                <div css={`display:flex;`}>
                                    <div css={`flex: .35;margin: 0 30px 0 0;`}>
                                        <div className="bInfo_title"><span style={{ color: "red" }}>*</span>单元描述</div>
                                        <div><textarea rows="4" placeholder="课程描述" className="binfo_input" cols="" value={unitJson[unitIndex].courseText} onChange={v => this.updateUnitTextVal(v, unitIndex)}></textarea></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="chapter_btnbox" onClick={this.addunit}>
                            <div className="icon_add">
                                <i></i>
                                <i></i>
                            </div>
                            <div className="chapter_btn_w">新增单元</div>
                        </div>
                    </div> : ""
                    }
                    {steps == 3 ? <div div className="basic_info_box">
                        <h3 className="Info_title">上传课程章节内容:章节目录信息填写</h3>
                        <div className="cru_selectBox">
                            {unitJson.length > 0 && unitJson.map((v, i) => (
                                <div className={"cru_select " + (unitIndex == i ? "cru_selected" : "")} onClick={() => { this.setState({ unitIndex: i, chapterIndex: 0 }) }}>第{i + 1}单元</div>
                            ))}
                            <img src={require('../assets/img/manage/line.png')} className={`cru_line`} style={{ left: (window.$(".cru_select")[unitIndex] && window.$(".cru_select")[unitIndex].offsetLeft + "px") }} />
                        </div>
                        <div>
                            <div className="unit_title">
                                {`第${unitIndex + 1}单元：${unitJson[unitIndex].courseName}`}
                            </div>
                            <div className="chapter_box">
                                {unitJson[unitIndex].chapterInfo.map((v, i) => (
                                    <div className="chapter_contentbox" key={i}>
                                        <div>
                                            第{i + 1}章
                                        </div>
                                        <div>
                                            <input type="text" placeholder="章节名称" className="binfo_input" value={v.title} onChange={(e) => this.updateChapterTitleVal(e, unitIndex, i)} />
                                        </div>
                                        {
                                            unitJson[unitIndex].chapterInfo.length > 1 ? <div onClick={() => this.deleteChapter(unitIndex, i)}></div> : ""
                                        }
                                    </div>
                                ))}
                            </div>
                            <div className="chapter_btnbox" onClick={() => { this.addChapter(unitIndex) }}>
                                <div className="icon_add">
                                    <i></i>
                                    <i></i>
                                </div>
                                <div className="chapter_btn_w">新增章节</div>
                            </div>
                        </div>
                    </div> : ""
                    }
                    {/* {steps == 4 ? <div className="basic_info_box">
                        <h3 className="Info_title">上传课程章节内容:章节资料上传</h3>
                        <hr className="info_line" />
                        <div className="chapter_info1">
                            <div className="chapter_info_box">
                                <div className="chapter_info_title">第{chapterIndex + 1}章:{chapterInfo[chapterIndex].title}</div>
                            </div>
                            <div>
                                <div className="bInfo_title">
                                    <span style={{ color: "red" }}>*</span>课程名称
                                </div>
                                <div>
                                    <input type="text" placeholder="课程名称" className="binfo_input" value={chapterInfo[chapterIndex].courseName} onChange={(e) => this.updateChapterCourseNameVal(e, chapterIndex)} />
                                </div>
                            </div>
                            <div className="add_info_box">
                                <button className="info_btn" onClick={(e) => this.add(e, chapterIndex)}>
                                    添加视频
                                    <input type="file"
                                        accept="video/mp4,video/quicktime,video/x-msvideo"
                                        style={{ display: "none" }}
                                        onChange={(e) => this.upload(e)} />
                                </button>
                                <button className="info_btn" onClick={() => this.handleClickOpen(chapterIndex)}>添加测验</button>
                                <button className="info_btn" onClick={() => this.handleClickPOpen(chapterIndex)}>添加编程平台</button>
                            </div>
                            <div>
                                <div className="add_chapters_box">
                                    {chapterInfo[chapterIndex].chapterData && chapterInfo[chapterIndex].chapterData.length > 0 && chapterInfo[chapterIndex].chapterData.map((v, ic) => (
                                        <div className="chapter_upload" key={ic} onClick={(e) => this.getChapterData(e, chapterIndex, ic, v.typeName)}>
                                            <div className="chapter_upload_t" style={{ width: "100%" }}></div>
                                            <div className="chapter_upload_o">
                                                <div className="chapter_upload_l">
                                                    {
                                                        v.typeName == "视频" ? (<div className="chapter_upload_l_i1"></div>) : (v.typeName == "测验" ? (<div className="chapter_upload_l_i2"></div>) : (<div className="chapter_upload_l_i3"></div>))
                                                    }
                                                </div>
                                                <div className="chapter_upload_ic">
                                                    <div className="chapter_upload_ic_l"></div>
                                                    <div className="chapter_upload_ic_r" onClick={(e) => this.deleteChapterData(e, chapterIndex, ic)}>
                                                        <div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="chapter_upload_n" style={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
                                                    {
                                                        v.typeName == "视频" ? (<input placeholder={v.minutiaName} onChange={(e) => this.updataVideoT(e, chapterIndex, ic)} style={{ border: "none", outline: "none", width: "55%", minWidth: "215px" }} />)
                                                            : (<input placeholder={v.minutiaName} style={{ border: "none", outline: "none", width: "55%", minWidth: "215px" }} readonly="true" />)
                                                    }
                                                    <div className="chapter_upload_ud">
                                                        <div className="chapter_upload_up" onClick={(e) => this.upCd(e, chapterIndex, ic)}></div>
                                                        <div className="chapter_upload_down" onClick={(e) => this.downCd(e, chapterIndex, ic)}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="chapter_info_btnbox">
                            {chapterIndex > 0 ? <button className="info_btn" onClick={this.lastChapter}>上一章</button> : ""}
                            {chapterIndex < chapterInfo.length - 1 ? <button className="info_btn" onClick={this.nextChapter}>下一章</button> : ""}
                        </div>
                        <hr className="info_line" />
                    </div> : ""
                    } */}
                    {steps == 4 ? <div className="basic_info_box">
                        <h3 className="Info_title">课程内容填写</h3>
                        <div>
                            <div className="bInfo_title"><span style={{ color: "red" }}>*</span>课程名称</div>
                            <div><input type="text" placeholder="课程名称" className="binfo_input" value={courseName} onChange={v => this.updatetitleVal(v)} /></div>
                        </div>
                        <div>
                            <div className="bInfo_title"><span style={{ color: "red" }}>*</span>课程描述</div>
                            <div><textarea rows="4" placeholder="课程描述" className="binfo_input" cols="" value={courseText} onChange={v => this.updateTextVal(v)}></textarea></div>
                        </div>
                        {/* <div>
                            <div className="bInfo_title"><span style={{ color: "red" }}>*</span>公告</div>
                            <div><input type="text" placeholder="公告" className="binfo_input" value={notice} onChange={v => this.updateNoticeVal(v)} /></div>
                        </div> */}
                        <div css={`display:flex`}>
                            <div css={`flex: .5;margin: 0 20px 0 0;`}>
                                <div className="bInfo_title">
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                    课程封面
                                    <div className="chapter_beizhu">
                                        PNG, GIF, JPEG
                                    </div>
                                </div>
                                <CoverUploadPreview
                                    getCoverVal={this.getCoverVal}
                                    language={language}
                                />
                            </div>
                            <div css={`flex: .5;`}>
                                <div className="bInfo_title">
                                    {/* <span style={{ color: "red" }}>*</span> */}
                                    课程预览视频
                                    <div className="chapter_beizhu">
                                        MP4, M4V, MOV, AVI
                                    </div>
                                </div>
                                <DemoUploadPreview
                                    getDemoVal={this.getDemoVal}
                                    language={language}
                                />
                            </div>
                        </div>
                        <hr className="info_line" />
                        <div className="cru_selectBox">
                            {unitJson.length > 0 && unitJson.map((v, i) => (
                                <div className={"cru_select " + (unitIndex == i ? "cru_selected" : "")} onClick={() => { this.setState({ unitIndex: i, chapterIndex: 0 }) }}>第{i + 1}单元</div>
                            ))}
                            <img src={require('../assets/img/manage/line.png')} className={`cru_line`} style={{ left: (window.$(".cru_select")[unitIndex] && window.$(".cru_select")[unitIndex].offsetLeft + "px") }} />
                        </div>
                        <div>
                            <div className="chapter_contentbox">
                                <div>
                                    第{unitIndex + 1}单元
                                </div>
                                <div>
                                    <input type="text" placeholder="单元标题" className="binfo_input" value={unitJson[unitIndex].courseName} onChange={(e) => this.updateUnitVal(e, unitIndex)} />
                                </div>
                                {
                                    unitJson.length > 1 ? <div onClick={() => this.deleteUnit(unitIndex)}></div> : ""
                                }
                            </div>
                            <div css={`display:flex`}>
                                <div css={`flex: .35;margin: 0 30px 0 0;`}>
                                    <div className="bInfo_title">
                                        {/* <span style={{ color: "red" }}>*</span> */}
                                        单元封面
                                        <div className="chapter_beizhu">
                                            PNG, GIF, JPEG
                                        </div>
                                    </div>
                                    <div className="chapter_uploadBox1">
                                        {unitJson[unitIndex].cover.length == 0 ? <div className="chapter_add" onClick={(e) => this.addImg(e, unitIndex)}>
                                            <div className="chapter_add_l">+</div>
                                            <div className="chapter_add_r">添加文件</div>
                                            <input type="file"
                                                accept="image/png,image/gif,image/jpeg,"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.uploadImg(e, unitIndex)} />
                                        </div> :
                                            <div className="chapter_upload_move" style={{ height: "100%" }}>
                                                <div className="chapter_upload_t" style={{ width: "100%" }}></div>
                                                <div className="chapter_upload_o" style={{ top: "7px" }}>
                                                    <div className="chapter_upload_l" onClick={() => this.openImg(unitIndex)}>
                                                        <div className="chapter_upload_l_i"></div>
                                                    </div>
                                                    <div className="chapter_upload_ic">
                                                        <div className="chapter_upload_ic_l" onClick={() => this.openImg(unitIndex)}>
                                                            <div style={{ backgroundPosition: "-5px -131px", display: "block" }}>
                                                            </div></div>
                                                        <div className="chapter_upload_ic_r" style={{ cursor: "pointer" }}>
                                                            <div onClick={() => this.deleteUnitImg(unitIndex)}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="chapter_upload_n" onClick={() => this.openImg(unitIndex)}>{unitJson[unitIndex].cover[0].substr(unitJson[unitIndex].cover[0].lastIndexOf("/")).slice(1)}</div>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                <div css={`flex: .35;margin: 0 10px 0 0;`}>
                                    <div className="bInfo_title"><span style={{ color: "red" }}>*</span>公告</div>
                                    <div><input type="text" placeholder="公告" className="binfo_input" value={unitJson[unitIndex].notice} onChange={v => this.updateUnitNoticeVal(v, unitIndex)} /></div>
                                </div>
                            </div>
                            <div css={`display:flex;`}>
                                <div css={`flex: .35;margin: 0 30px 0 0;`}>
                                    <div className="bInfo_title"><span style={{ color: "red" }}>*</span>单元描述</div>
                                    <div><textarea rows="4" placeholder="课程描述" className="binfo_input" cols="" value={unitJson[unitIndex].courseText} onChange={v => this.updateUnitTextVal(v, unitIndex)}></textarea></div>
                                </div>
                            </div>

                            {unitJson[unitIndex].chapterInfo.map((c, j) => (
                                <div className="chapter_info1" key={unitIndex + "" + j}>
                                    <div className="chapter_info_box">
                                        <div className="chapter_info_title">
                                            <span>第{j + 1}章:{c.title}</span>
                                            {
                                                unitJson[unitIndex].chapterInfo.length > 1 ? <img src={require('../assets/img/manage/delete.png')} className="" onClick={() => this.deleteChapter(unitIndex, j)} /> : ""
                                            }
                                        </div>
                                    </div>
                                    {/* <div>
                                                <div className="bInfo_title">
                                                    <span style={{ color: "red" }}>*</span>课程名称
                                                </div>
                                                <div>
                                                    <input type="text" placeholder="课程名称" className="binfo_input" value={c.courseName} onChange={(e) => this.updateChapterCourseNameVal(e, i, j)} />
                                                </div>
                                            </div> */}
                                    <div className="add_info_box">
                                        <button className="info_btn" onClick={(e) => this.add(e, unitIndex, j)}>
                                            添加视频
                                            <input type="file"
                                                accept="video/mp4,video/quicktime,video/x-msvideo"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.upload(e, unitIndex, j)} />
                                        </button>
                                        <button className="info_btn" onClick={(e) => this.add(e, unitIndex, j)}>
                                            添加附件
                                            <input type="file"
                                                accept="application/pdf,.ppt,.pptx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.upload2(e, unitIndex, j)} />
                                        </button>
                                        {/* <button className="info_btn" onClick={(e) => this.add(e, unitIndex, j)}>
                                            添加word
                                            <input type="file"
                                                accept="application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.upload2(e, unitIndex, j)} />
                                        </button>
                                        <button className="info_btn" onClick={(e) => this.add(e, unitIndex, j)}>
                                            添加ppt
                                            <input type="file"
                                                accept=".ppt,.pptx"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.upload2(e, unitIndex, j)} />
                                        </button> */}
                                        <button className="info_btn" onClick={() => this.handleClickOpen(unitIndex, j)}>添加测验</button>
                                        <button className="info_btn" onClick={() => this.handleClickPOpen(unitIndex, j)}>添加编程平台</button>
                                        <button className="info_btn" onClick={() => this.handleClickHtmlOpen(unitIndex, j)}>添加网页</button>
                                    </div>
                                    <div>
                                        <div className="add_chapters_box">
                                            {c.chapterData && c.chapterData.length > 0 && c.chapterData.map((v, ic) => (
                                                <div className="chapter_upload" key={ic} onClick={(e) => this.getChapterData(e, unitIndex, j, ic, v.typeName)}>
                                                    <div className="chapter_upload_t" style={{ width: "100%" }}></div>
                                                    <div className="chapter_upload_o">
                                                        <div className="chapter_upload_l">
                                                            {
                                                                v.typeName == "视频" ?
                                                                    (<div className="chapter_upload_l_i1"></div>)
                                                                    :
                                                                    (
                                                                        v.typeName == "测验" ?
                                                                            (<div className="chapter_upload_l_i2"></div>) :
                                                                            (v.typeName == "编程" ?
                                                                                (<div className="chapter_upload_l_i3"></div>) :
                                                                                v.typeName == "PDF" ?
                                                                                    (<div className="chapter_upload_l_i4"></div>) :
                                                                                    v.typeName == "DOC" ?
                                                                                        (<div className="chapter_upload_l_i5"></div>) : 
                                                                                        v.typeName == "PPT" ?
                                                                                            (<div className="chapter_upload_l_i6"></div>) : (<div className="chapter_upload_l_i7"></div>)
                                                                            )
                                                                    )
                                                            }
                                                        </div>
                                                        <div className="chapter_upload_ic">
                                                            <div className="chapter_upload_ic_l"></div>
                                                            <div className="chapter_upload_ic_r" onClick={(e) => this.deleteChapterData(e, unitIndex, j, ic)}>
                                                                <div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="chapter_upload_n" style={{ display: "flex", alignItems: "center", paddingLeft: "10px" }}>
                                                            {
                                                                (v.typeName == "视频" || v.typeName == "PDF" || v.typeName == "DOC" || v.typeName == "PPT") ? (<input placeholder={v.minutiaName} onChange={(e) => this.updataVideoT(e, unitIndex, j, ic)} style={{ border: "none", outline: "none", width: "55%", minWidth: "215px" }} />)
                                                                    : (<input placeholder={v.minutiaName} style={{ border: "none", outline: "none", width: "55%", minWidth: "215px" }} readonly="true" />)
                                                            }
                                                            <div className="chapter_upload_ud">
                                                                <div className="chapter_upload_up" onClick={(e) => this.upCd(e, unitIndex, j, ic)}></div>
                                                                <div className="chapter_upload_down" onClick={(e) => this.downCd(e, unitIndex, j, ic)}></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div style={{padding:'10px 0'}}>
                                            <BraftEditor value={unitJson[unitIndex].chapterInfo[j].editorValue} style={{border:'1px solid #e8e8e8'}}
                                                placeholder="请输入内容" onChange={(e)=>this.editorChange(e, unitIndex, j)} 
                                                // extendControls={extendControls}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <hr className="info_line" />
                    </div> : ""}
                    {steps == 5 ? <div className="basic_info_box">
                        <div className="success_box">
                            <img className="success_img" src={require('../assets/img/Result__success2.svg')} />
                            <h1>成功</h1>
                            <div className="success_n">
                                您的课程编号
                            </div>
                            <div className="success_num">
                                {number}
                            </div>
                            <button className="info_btn" style={{ padding: "10px 100px" }} onClick={this.redirectToCourse}>预览课程</button>
                        </div>
                    </div> : ""}
                    <div className="info_btnBox">
                        {steps == 1 ? <button className="info_btn" onClick={() => { this.props.history.push('/') }}>返回首页</button> : ""}
                        {steps > 1 && steps != 5 ? <button className="info_btn" onClick={this.lastStep}>{steps == 4 ? "返回修改" : "上一步"}</button> : ""}
                        {steps < 5 ? <button className="info_btn" onClick={this.nextStep}>{steps == 4 ? "确认上传" : "下一步"}</button> : ""}
                        {/* <button class ="info_btn">返回首页</button> */}
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="1000px"
                >
                    <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
                        <div css={`color:#fff;font-size: 18px;`}>创建章节测验</div>
                    </DialogTitle>
                    <DialogContent style={{ background: "#ededed" }}>
                        <div className="testBox">
                            <div className="testTitle">
                                <div><span style={{ minWidth: "55px" }}>章节:</span><input type="text" value={unitJson[unitIndex].chapterInfo[chapterIndex].title} readOnly={true} />
                                    {/* <span style={{ fontSize: "18px", fontWeight: "600" }}>{chapterInfo[chapterIndex].title}</span> */}
                                </div>
                                <div><span style={{ width: "105px" }}>测验标题:</span><input type="text" value={testData.minutiaName} onChange={v => this.updateTestTitleVal(v)} /></div>
                            </div>
                            <div className="testContent">
                                {
                                    testData.contentData.map((v, i) => (
                                        <div className="testRadioBox" key={i}>
                                            <div className="testContentTitle">
                                                <div style={{ display: 'block', width: "100%" }}>
                                                    <div className="checkBBB">
                                                        <div className="testBoxCheck">
                                                            <span className={testData.contentData[i].type === true ? 'active' : ''} onClick={() => { this.checkType(i, true) }}>单选题</span>
                                                            <span className={!testData.contentData[i].type ? 'active' : ''} onClick={() => { this.checkType(i, false) }}>多选题</span>
                                                            <span className={testData.contentData[i].type === 1 ? 'active' : ''} onClick={() => { this.checkType(i, 1) }}>填空题</span>
                                                            <span className={testData.contentData[i].type === true ? 'line' : !testData.contentData[i].type ? 'line lineX' : 'line lineZ'} onClick={() => { this.checkType(i, false) }}></span>
                                                        </div>
                                                        <div>
                                                            {/* {i == 0 ? <button className="info_btn addBtn" css={`margin-right:10px`} onClick={() => this.addTests()}>
                                                                导入题目
                                                            </button> : ""} */}
                                                            {i == 0 ? <button className="info_btn addBtn" css={`margin-right:10px`} onClick={() => this.addTest()}>
                                                                添加题目
                                                            </button> : ""}
                                                            {testData.contentData.length > 1 ? <button className="info_btn deleteBtn" onClick={() => this.deleteTest(i)}>
                                                                删除
                                                            </button> : ""}
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '10px', width: "60%" }}><span>{i + 1}.题目:</span><input type="text" value={testData.contentData[i].titleName} onChange={v => this.updateTestVal(v, i)} /></div>
                                                </div>
                                            </div>
                                            <div className="testRadio">
                                                <div style={{ flexFlow: "column", justifyContent: "center", alignItems: "flex-start", marginTop: '0px' }}>
                                                    {!testData.contentData[i].type ? (
                                                        <div style={{ flexDirection: "column", marginTop: '0px' }}><div><span style={{ width: "15px" }}>A.</span><input type="text" value={testData.contentData[i].radio[0]} onChange={v => this.updateRadioVal(v, i, 0, testData.contentData[i].radio[0])} /><input type="checkbox" onClick={() => { this.checkAnswer(i, "A") }} checked={testData.contentData[i].answer.indexOf("A") != -1} /></div>
                                                            <div><span style={{ width: "15px" }}>B.</span><input type="text" value={testData.contentData[i].radio[1]} onChange={v => this.updateRadioVal(v, i, 1, testData.contentData[i].radio[1])} /><input type="checkbox" onClick={() => { this.checkAnswer(i, "B") }} checked={testData.contentData[i].answer.indexOf("B") != -1} /></div>
                                                            <div><span style={{ width: "15px" }}>C.</span><input type="text" value={testData.contentData[i].radio[2]} onChange={v => this.updateRadioVal(v, i, 2, testData.contentData[i].radio[2])} /><input type="checkbox" onClick={() => { this.checkAnswer(i, "C") }} checked={testData.contentData[i].answer.indexOf("C") != -1} /></div>
                                                            <div><span style={{ width: "15px" }}>D.</span><input type="text" value={testData.contentData[i].radio[3]} onChange={v => this.updateRadioVal(v, i, 3, testData.contentData[i].radio[3])} /><input type="checkbox" onClick={() => { this.checkAnswer(i, "D") }} checked={testData.contentData[i].answer.indexOf("D") != -1} /></div></div>)
                                                        :
                                                        testData.contentData[i].type === true ? (<div style={{ flexDirection: "column", marginTop: '0px' }}>
                                                            <div><span style={{ width: "15px" }}>A.</span><input type="text" value={testData.contentData[i].radio[0]} onChange={v => this.updateRadioVal(v, i, 0, testData.contentData[i].radio[0])} /><input style={{ marginLeft: '5px', cursor: 'pointer' }} name={i} type="radio" onClick={() => { this.checkAnswer(i, "A") }} checked={testData.contentData[i].answer.indexOf("A") != -1} /></div>
                                                            <div><span style={{ width: "15px" }}>B.</span><input type="text" value={testData.contentData[i].radio[1]} onChange={v => this.updateRadioVal(v, i, 1, testData.contentData[i].radio[1])} /><input style={{ marginLeft: '5px', cursor: 'pointer' }} name={i} type="radio" onClick={() => { this.checkAnswer(i, "B") }} checked={testData.contentData[i].answer.indexOf("B") != -1} /></div>
                                                            <div><span style={{ width: "15px" }}>C.</span><input type="text" value={testData.contentData[i].radio[2]} onChange={v => this.updateRadioVal(v, i, 2, testData.contentData[i].radio[2])} /><input style={{ marginLeft: '5px', cursor: 'pointer' }} name={i} type="radio" onClick={() => { this.checkAnswer(i, "C") }} checked={testData.contentData[i].answer.indexOf("C") != -1} /></div>
                                                            <div><span style={{ width: "15px" }}>D.</span><input type="text" value={testData.contentData[i].radio[3]} onChange={v => this.updateRadioVal(v, i, 3, testData.contentData[i].radio[3])} /><input style={{ marginLeft: '5px', cursor: 'pointer' }} name={i} type="radio" onClick={() => { this.checkAnswer(i, "D") }} checked={testData.contentData[i].answer.indexOf("D") != -1} /></div>
                                                        </div>) : (<div style={{ flexDirection: "column", marginTop: '0px' }}>
                                                            <div><span style={{ width: "80px" }}>正确答案:</span><input style={{ marginLeft: "0px" }} type="text" value={testData.contentData[i].answer[0]} onChange={v => this.checkAnswer(i, "A", v)} /></div>
                                                        </div>)}
                                                    {/* {testData.contentData[i].radio.length < 6 ? <button className="addRadioBtn" css={`margin-right:10px`} onClick={() => this.addRadio(i)}>
                                                        <img src={require('../assets/img/add.png')} />
                                                    </button> : ""}
                                                    {testData.contentData[i].radio.length > 2 ? <button className="deleteRadioBtn" onClick={() => this.deleteRadio(i)}>
                                                        <img src={require('../assets/img/reduce.png')} />
                                                    </button> : ""} */}
                                                </div>
                                            </div>
                                            <div className="testAnserBox">
                                                <div><span>答案解析:</span><input type="text" value={testData.contentData[i].answerAnalysis} onChange={v => this.updateAnswerVal(v, i)} /></div>
                                            </div>
                                            <div className="testAnserBox">
                                                {
                                                    testData.contentData[i].type === 1 ? '' : (<div><span>正确答案:</span><span>{testData.contentData[i].answer.length > 0 ? testData.contentData[i].answer.join(",") : <span css={`color:#ababab`}>点击选项右边“选择框”勾选答案</span>}</span></div>)
                                                }
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ background: "#ededed", padding: "8px 4px", margin: "0" }}>
                        <div className="btnBox">
                            <div>
                                答题倒计时:<input type="text" className="countInput" value={testData.time} onChange={v => this.updateTimeVal(v)} />分钟
                            </div>
                            <div>
                                <button className="info_btn cancelButton" css={`margin-right:10px`} onClick={this.handleClose}>
                                    取消
                                </button>
                                <button className="info_btn" onClick={this.confirmTest}>
                                    确定
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={open2}
                    onClose={this.handleClose2}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="1000px"
                >
                    <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
                        <div css={`color:#fff;font-size: 18px;`}>创建章节编程</div>
                    </DialogTitle>
                    <DialogContent style={{ background: "#ededed" }}>
                        <div className="testBox">
                            <div className="testTitle">
                                <div><span style={{ minWidth: "55px" }}>章节:</span><input type="text" value={unitJson[unitIndex].chapterInfo[chapterIndex].title} readOnly={true} />
                                    {/* <span style={{ fontSize: "18px", fontWeight: "600" }}>{chapterInfo[chapterIndex].title}</span> */}
                                </div>
                                <div><span style={{ width: "105px" }}>编程标题:</span><input type="text" value={pData.minutiaName} onChange={v => this.updatePTitleVal(v)} /></div>
                            </div>
                            <div>
                                <div style={{ display: "flex" }}><span style={{ width: "59px" }}>描述:</span><textarea type="text" className="code_cu_textarea" value={pData.detail} onChange={v => this.updatePDetailVal(v)} ></textarea></div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ background: "#ededed", padding: "8px 4px", margin: "0" }}>
                        <div className="btnBox">
                            <div></div>
                            <div>
                                <button className="info_btn cancelButton" css={`margin-right:10px`} onClick={this.handleClose2}>
                                    取消
                                </button>
                                <button className="info_btn" onClick={this.confirmP}>
                                    确定
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={open4}
                    onClose={this.handleClose4}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="1000px"
                >
                    <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
                        <div css={`color:#fff;font-size: 18px;`}>添加网页</div>
                    </DialogTitle>
                    <DialogContent style={{ background: "#ededed" }}>
                        <div className="testBox">
                            <div className="testTitle">
                                <div><span style={{ minWidth: "55px" }}>章节:</span><input type="text" value={unitJson[unitIndex].chapterInfo[chapterIndex].title} readOnly={true} />
                                    {/* <span style={{ fontSize: "18px", fontWeight: "600" }}>{chapterInfo[chapterIndex].title}</span> */}
                                </div>
                                <div><span style={{ width: "105px" }}>网页链接:</span><input type="text" value={htmlData.minutiaName} onChange={v => this.updateHtmlTitleVal(v)} /></div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ background: "#ededed", padding: "8px 4px", margin: "0" }}>
                        <div className="btnBox">
                            <div></div>
                            <div>
                                <button className="info_btn cancelButton" css={`margin-right:10px`} onClick={this.handleClose4}>
                                    取消
                                </button>
                                <button className="info_btn" onClick={this.confirmHtml}>
                                    确定
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={this.state.loading}
                    onClose={() => {
                        this.setState({
                            loading: true
                        })
                    }}
                >
                    <div style={{ width: '100%', height: '100%' }}>
                        <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
                        <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
                    </div>
                </Modal>
                <Modal
                    open={modalShow}
                >
                    <div >
                        <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
                        <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{ccc}</p>
                    </div>
                </Modal>
                <Dialog
                    open={open3}
                    onClose={this.handleClose3}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="1000px"
                >
                    <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
                        <div css={`color:#fff;font-size: 18px;`}>导入题目</div>
                    </DialogTitle>
                    <DialogContent style={{ background: "#ededed" }}>
                        <div className="testBox">
                            <div className="importButton">
                                <button className="info_btn addBtn" onClick={() => { this.emptyText() }}>
                                    清空
                                </button>
                            </div>
                            <div className="importContent">
                                <div style={{ display: "flex", flex: ".5", marginRight: "20px" }}><span style={{ width: "59px" }}>题目:</span><textarea type="text" className="code_cu_textarea" value={testInnerHTML} onChange={v => this.updatetestInner(v)} ></textarea></div>
                                <div style={{ display: "flex", flex: ".5" }}><span style={{ width: "59px" }}>效果:</span>
                                    {/* <textarea readonly="true" type="text" className="code_cu_textarea" value={testInnerHTML} ></textarea> */}
                                    <div className="textI">{
                                        testInnerJson.length > 0 && testInnerJson.map((v, i) => (
                                            <div>
                                                <div style={{ marginTop: i != 0 ? '10px' : 0 }}><span style={{ fontSize: "16px" }}>{i + 1}.题目:</span><span style={{ fontSize: "16px" }}>{v.titleName}</span></div>
                                                <div className="testRadio">
                                                    <div style={{ flexFlow: "column", justifyContent: "flex-start", alignItems: "flex-start", marginTop: '0px' }}>
                                                        {!v.type ? (
                                                            <div style={{ flexDirection: "column", marginTop: '0px', alignItems: "flex-start" }}>
                                                                <div style={{ display: "unset" }}><input style={{ marginLeft: '0px' }} type="checkbox" disabled={true} /><span style={{ width: "15px" }}>A.</span><span >{v.radio[0]}</span></div>
                                                                <div style={{ display: "unset" }}><input style={{ marginLeft: '0px' }} type="checkbox" disabled={true} /><span style={{ width: "15px" }}>B.</span><span >{v.radio[1]}</span></div>
                                                                <div style={{ display: "unset" }}><input style={{ marginLeft: '0px' }} type="checkbox" disabled={true} /><span style={{ width: "15px" }}>C.</span><span >{v.radio[2]}</span></div>
                                                                <div style={{ display: "unset" }}><input style={{ marginLeft: '0px' }} type="checkbox" disabled={true} /><span style={{ width: "15px" }}>D.</span><span >{v.radio[3]}</span></div></div>)
                                                            :
                                                            v.type === true ? (<div style={{ flexDirection: "column", marginTop: '0px', alignItems: "flex-start" }}>
                                                                <div style={{ display: "unset" }}><input style={{ margin: '0px 5px 0 0' }} name={i} type="radio" disabled={true} /><span style={{ width: "15px" }}>A.</span><span >{v.radio[0]}</span></div>
                                                                <div style={{ display: "unset" }}><input style={{ margin: '0px 5px 0 0' }} name={i} type="radio" disabled={true} /><span style={{ width: "15px" }}>B.</span><span >{v.radio[1]}</span></div>
                                                                <div style={{ display: "unset" }}><input style={{ margin: '0px 5px 0 0' }} name={i} type="radio" disabled={true} /><span style={{ width: "15px" }}>C.</span><span >{v.radio[2]}</span></div>
                                                                <div style={{ display: "unset" }}><input style={{ margin: '0px 5px 0 0' }} name={i} type="radio" disabled={true} /><span style={{ width: "15px" }}>D.</span><span >{v.radio[3]}</span></div>
                                                            </div>) : (<div style={{ flexDirection: "column", marginTop: '0px', alignItems: "flex-start" }}>
                                                                <div style={{ display: "unset" }}>
                                                                    <span style={{ width: "80px" }}>正确答案:</span>
                                                                    <span style={{ marginLeft: "0px" }} type="text">{v.answer[0]}</span>
                                                                </div>
                                                            </div>)}
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: "10px" }}><span style={{ fontSize: "15px" }}>答案解析:</span><span style={{ fontSize: "15px" }} type="text">{v.answerAnalysis}</span></div>
                                            </div>
                                        ))
                                    }</div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ background: "#ededed", padding: "8px 4px", margin: "0" }}>
                        <div className="btnBox">
                            <div></div>
                            <div>
                                <button className="info_btn cancelButton" css={`margin-right:10px`} onClick={this.handleClose3}>
                                    取消
                                </button>
                                <button className="info_btn" onClick={this.addTestsData}>
                                    确定
                                </button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseUpload);