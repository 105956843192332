import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios';
import config from '../config'
import '../css/CourseManage.css'
import StudentState from '../components/studentState'
import StudentJuri from '../components/studentJuri'
import StudentChat from '../components/studentChat'
import TestManageCM from '../components/testManageCM'
import HomeworkCM from '../components/homeworkCM'
import StudentSumUp from '../components/StudentSumUp'
import StudentRank from '../components/studentRank'

export class CourseManage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            number: "",
            courseName: "",
            courseId: "",
            userId: "",
            juri: "",
            lineStyle: "",
            type: 0,
            pid: "",
            chapter: "",
            ptitle: "",
            pNumber: "",
            count:0
        }
    }

    componentDidMount() {
        const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
        var _number = window.U.UF.C.queryString("number") || '';
        var _this = this;
        let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
        if (arr) {
            this.props.history.push('/login')
        } else {
            this.getUserId()
        }
        if (_number) {
            this.setState({
                number: _number
            })
            _this.getCourse(_number)
        }
    }
    getCourse = (number) => {
        axios.get(`${config.env.edu}getUnitDetail/${number}`).then(res => {
            console.log(res)
            let _courseinfo = res.data[0][0]
            setTimeout(() => {
                this.setState({
                    courseName: _courseinfo.title,//课程名称
                    courseId: _courseinfo.courseId,
                    juri: _courseinfo.juri,
                    userId: _courseinfo.userid,
                    pid: _courseinfo.pid,
                    chapter: JSON.parse(_courseinfo.chapters),
                    ptitle: _courseinfo.title,
                    pNumber: _courseinfo.pNumber,
                })
            }, 0);
        }).catch(err => console.log(err));
    }
    getUserId = () => {
        var _this = this;
        axios.get(`${config.env.server}getcookieuserid`).then(res => {
            // if (res.data[0][0]) {
            //     _this.setState({
            //         userId: res.data[0][0].userid
            //     });
            // }
        }).catch(err => {
            _this.props.history.push('/login');
            // window.alert(err.response.data)
        })
    }

    check = (type) => {
        let lineStyle = ""
        let _nav = window.$(".cm_nav")
        let _line = window.$(".cm_line")[0]
        if (type == 0) {
            _line.style.left = _nav[0].offsetLeft + "px"
        } else if (type == 1) {
            lineStyle = "cm_line_chat"
            _line.style.left = _nav[1].offsetLeft + "px"
        } else if (type == 2) {
            lineStyle = "cm_line_juri"
            _line.style.left = _nav[2].offsetLeft + "px"
        } else if (type == 3) {
            lineStyle = "cm_line_test"
            _line.style.left = _nav[3].offsetLeft + "px"
        } else if (type == 4) {
            lineStyle = "cm_line_code"
            _line.style.left = _nav[4].offsetLeft + "px"
        } else if (type == 5) {
            lineStyle = "cm_line_sumup"
            _line.style.left = _nav[5].offsetLeft + "px"
        } else if (type == 6) {
            _line.style.left = _nav[6].offsetLeft + "px"
        }

        this.setState({
            lineStyle: lineStyle,
            type: type
        })
    }

    setCount = (num) => {
        this.setState({
            count:num
        })
    }

    render() {
        const { courseName, type, lineStyle, courseId, userId, juri, number, pid, chapter, ptitle, pNumber,count } = this.state
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#eee", minWidth: "950px" }}>
                <div className="cm_container">
                    <div className="cm_title">
                        <img src={require('../assets/img/manage/class.png')} />
                        <span>{ptitle +"-"+ courseName}</span> <span style={{fontSize:"19px",color:"#646464",marginTop:"13px"}}>{"编号:"+pNumber}</span>
                    </div>
                    <div className="cm_navs">
                        <div className="cm_nav" onClick={() => { this.check(0) }}>
                            <img src={require('../assets/img/manage/juri.png')} />
                            <span>学生权限</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(1) }}>
                            <img src={require('../assets/img/manage/chat.png')} />
                            <span>聊天室</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(2) }}>
                            <img src={require('../assets/img/manage/state.png')} />
                            <span>学习进度</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(3) }}>
                            <img src={require('../assets/img/manage/test.png')} />
                            <span>测验查看</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(4) }}>
                            <img src={require('../assets/img/manage/code.png')} />
                            <span>编程作业</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(5) }}>
                            <img src={require('../assets/img/manage/sumup.png')} />
                            <span>项目总结</span>
                        </div>
                        <div className="cm_nav" onClick={() => { this.check(6) }}>
                            <img src={require('../assets/img/manage/rank.png')} />
                            <span>排行榜</span>
                        </div>
                        <img src={require('../assets/img/manage/line.png')} className={`cm_line`} />
                    </div>
                    <div className="cm_content">
                        <div className="cm_class_container" style={{ display: type == 2 ? 'block' : "none" }}>
                            {/* <img src={require('../assets/img/manage/state2.png')} className="cm_class_state" /> */}
                            <div className="cm_class_state">{count}人在学习</div>
                            <StudentState courseId={courseId} set={this.setCount}></StudentState>
                        </div>
                        <div className="cm_class_container2" style={{ display: type == 1 ? 'block' : "none" }}><StudentChat number={number} userId={userId}></StudentChat></div>
                        <div className="cm_class_container2" style={{ display: type == 0 ? 'block' : "none" }}><StudentJuri courseId={pid} userId={userId} juri={juri} getCourse={this.getCourse}></StudentJuri>
                        </div>
                        <div className="cm_class_container2" style={{ display: type == 3 ? 'block' : "none" }}><TestManageCM courseId={courseId} chapter={chapter}></TestManageCM>
                        </div>
                        <div className="cm_class_container2" style={{ display: type == 4 ? 'block' : "none" }}><HomeworkCM courseId={courseId} chapter={chapter}></HomeworkCM></div>
                        <div className="cm_class_container2" style={{ display: type == 5 ? 'block' : "none" }}><StudentSumUp number={number} userId={userId}></StudentSumUp></div>
                        <div className="cm_class_container2" style={{ display: type == 6 ? 'block' : "none" }}><StudentRank courseId={courseId}></StudentRank></div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(CourseManage)
