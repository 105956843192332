import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import config from '../config'

//assets
import { ReactComponent as Logo } from '../assets/img/Footer__Logo.svg'

const Wrapper = styled.div`
  // position:absolute;
  // width:100%;
  // bottom:0;
  grid-area:${props => props.areaname};
  display:grid;
  height:67.5px;
  grid-template-columns:repeat(12, 1fr);
  grid-template-areas:
  'Logo . . . . . . . . . Reserved Reserved';
  background-color:#3D67BC;
`;
const Reserved = styled.p`
  display:block;
  // width:311px;
  grid-area:Reserved;
  place-self:center;
  color:#fff;
  font-size:16px;
  font-weight:800;
  position:absolute;
  right:calc(100% / 30);
  line-height:67.5px;
`;

class Footer extends React.Component {
  render() {
    return (
      <Wrapper areaname={this.props.areaname}>
        <a href={config.env.origin} css={`place-self:center;grid-area:Logo;`} alt='返回 CocoRobo 网站'>
          {/* <Logo
            width='50px'
            height='50px'
            css={`place-self:center;`}
          /> */}
          <img src={require('../assets/img/logo1.png')} style={{width:120}} />
        </a>
        <Reserved>© 天源股份2022版权所有.</Reserved>
      </Wrapper>
    )
  }
}

export default Footer;
