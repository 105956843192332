import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../css/profile.css'
import axios from 'axios';
import styled from 'styled-components'
import qs from 'qs';
import config from '../config'
//  import youjian from '../../public/img/youjian.jpg'
import { toggleUserDropdown } from '../redux/Actions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Modal, Table, Paper, TableHead, TableRow, TableBody, TableCell, TablePagination, Fade } from '@material-ui/core';


const modalStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}
const tableStyle = {
    border: '1px solid #eee',

}

const Button = styled.div`
       color: #fff;
    cursor: pointer;
    text-align: center;
    width: 90px;
    margin: 0 10px;
    background: #5190fd;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    font-size:16px
`

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#F3F4F6",
        color: '#000',
        fontSize: 18,
        fontWeight: "600",
        padding: 0

    },
    body: {
        fontSize: 18,
        padding: 0,
        width: "calc(100% / 7)"
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: "#f1f1f1",
        },
    },
}))(TableRow);

const table = {
    minWidth: 700,
    padding: "0 30px",
    marginTop: "10px"
}
export class examine extends Component {
    state = {
        userId: '',
        rows: [
        ],  // 学生数据
        rowsPerPage: 10,
        page: 0,
        count: 0,  // 查询的总数量
        pid: '0',
        addModal: false,
        typename: '',
        addClassModal: '',
        contentS: '',
        courseJson: [],
        courseArray: [],
        tid: ""
    }
    componentDidMount() {
        console.log(this.props)
        this.getData()
    }
    getData = () => {
        const { pid, page } = this.state
        let params = {
            page: page + 1,
        }
        axios.post(`${config.env.edu}getExamine`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                rows: res.data[0]
            })
            // }
        })
    }

    getCType = (id) => {
        var _this = this
        window.U.UF.UI.confirm("同意这个用户添加此课程权限吗？", function () {
            _this.submit(id, '1')
        }, function () {
            _this.submit(id, '2')
        }, '同意', '拒绝')
    }


    submit = (id, type) => {
        let params = {
            id: id,
            type: type,
        }
        axios.post(`${config.env.edu}sendExamine`, qs.stringify(params)).then(res => {
            window.U.Alert('添加成功')
            this.getData();
        }).catch(err => {
            window.U.Alert('添加失败')
        })
    }


    del = (id) => {
        var cid = id;
        var _this = this
        window.U.UF.UI.confirm("确定删除此班级吗?", function () {
            let params = {
                tid: cid,
            }
            axios.post(`${config.env.edu}deleteType`, qs.stringify(params)).then(v => {
                if (v.data === 1) {
                    _this.getData()
                    window.U.Alert('删除成功')
                } else {
                    window.U.Alert('删除失败')
                }
            })
        }, function () {
            return;
        })
    }

    addCourse = (id) => {
        this.setState({
            addClassModal: true,
            tid: id
        }, () => {
            this.getCourse();
        })
    }

    getCourse = (type) => {
        const { contentS, tid } = this.state
        let params = {
            content: contentS,
            tid: tid,
        }
        axios.post(`${config.env.edu}getTypeCourse`, qs.stringify(params)).then(res => {
            console.log(res)
            // if (res.data[0].length > 0) {
            this.setState({
                courseJson: res.data[0]
            })
            var array = []
            // if (res.data[1].length > 0) {
            for (var i = 0; i < res.data[1].length; i++) {
                array.push(res.data[1][i].courseid);
            }
            if (type != '1') {
                this.setState({
                    courseArray: array
                })
            }
            console.log(array)
            // }
            // }
        })
    }
    addSJosn = (cid) => {
        let { courseArray } = this.state
        if (courseArray.indexOf(cid) !== -1) {
            courseArray.splice(courseArray.indexOf(cid), 1)
        } else {
            courseArray.push(cid)
        }

        this.setState({
            courseArray: courseArray
        })
    }

    addCourseSubmit = () => {
        const { courseArray, tid } = this.state
        let params = {
            course: courseArray.join(","),
            tid: tid,
            uid: this.props.userId
        }
        axios.post(`${config.env.edu}addCourseType`, qs.stringify(params)).then(v => {
            this.getData()
            this.setState({
                addClassModal: false,
                tid: '',
                courseArray: []
            })
            window.U.Alert('添加成功')
        }).catch(err => {
            window.U.Alert('添加失败')
        })
    }
    render() {
        const { rows, page, rowsPerPage, count, pid, addModal, typename, addClassModal, contentS, courseJson, courseArray } = this.state;
        return (
            <div className="AddStudent">
                <div className="pb_head student">
                    审核管理
                    {pid != '0' ?
                        (<span><button onClick={() => { this.setState({ addModal: true }) }} >添加分类</button><button onClick={() => this.getCType(0)} >返回</button></span>)
                        :
                        ""}
                </div>
                <div style={table} component={Paper}>
                    {rows.length > 0 ? <Table style={tableStyle} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align="center">真实姓名</StyledTableCell>
                                <StyledTableCell align="center">申请课程</StyledTableCell>
                                <StyledTableCell align="center">学校</StyledTableCell>
                                <StyledTableCell align="center">身份</StyledTableCell>
                                <StyledTableCell align="center">联系方式</StyledTableCell>
                                <StyledTableCell align="center">是否审核</StyledTableCell>
                                <StyledTableCell align="center">操作</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.title}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.school}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.identity}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.phone}
                                    </StyledTableCell>
                                    <StyledTableCell align="center" component="th" scope="row">
                                        {row.is == '0' ? "未审核" : row.is == '2' ? '已拒绝' : '已同意'}
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        {row.is == '0' ? <Button onClick={() => this.getCType(row.id)}>通过审核</Button> : <Button>已审核</Button>}
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table> : <div style={{ textAlign: 'center', }}>
                        <img src={require('../assets/img/notcontent.png')} />
                    </div>}
                    {rows.length > 0 && <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage="每页显示条数"
                    />}
                </div>
            </div>
        )
    }
}



export default examine
