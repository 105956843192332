import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components';
import { ReactComponent as Logo } from '../assets/img/Nav__Logo.svg';
import { connect } from 'react-redux';
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { updateCoursenumber, updateCourseInfo, updateCourseCover, updateCourseDemo, initChapters } from '../actions/newupload';
import RoomModal from '../components/RoomModal';
import axios from 'axios';
import qs from 'qs';
import config from '../config'
import { setInterval } from 'timers';
import '../css/nav.css'

// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
const Wrapper = styled.div`
  text-align:center;
  width:100%;
  height:100%;
  grid-area:${props => props.areaname};
  background-color:#3D67BC;
  display:grid;
  position:relative;
  font-weight:550;
  grid-template-columns: repeat(12, calc(100% / 12));
  //when user enable
  grid-template-areas:
  'logo . . uploadselect . . . . . . . . user';
  // grid-template-areas:
  // '. logo . . . . . . . . . room';


  //small device
  @media screen and (max-width:1023px){
    grid-template-columns: repeat(12, calc(100% / 12));
    //when user enable
    grid-template-areas:
    'logo . logo logo logo . . user user user user user';
    // grid-template-areas:
    // '. logo logo logo logo . . . . room room room';
  }
`;

const User = styled.div`
  grid-area:user;
`;

const logoStyle = `
  place-self:center;
  grid-area:logo;
  position:absolute;
  left:40px;
`

const buttonStyle = `
padding:8px 15px;
background:#fff;
color:#3D67BC;
font-size:16px;
border-radius:5px;
`
// 选中的语言按钮样式
const languages = `
display:block;
width:75px;
padding:5px 10px;
// background:#fff;
color:#fff;
font-size:16px;
border:1px solid #ccc;
border-radius:5px;
margin-top:17px;
`
const liStyle = `
  // width:130px;
  position:relative;
  cursor:pointer;
  // left:35px;
`

const msgStyle = {
  width: 200,
  position: 'absolute',
  top: '55px',
  right: '50px',
  zIndex: 1,
  background: '#fff',
  lineHeight: 1,
  color: '#000',
  padding: '3px 5px',
  boxShadow: '0px 0px 10px #ccc',
  borderRadius: '4px',
  fontSize: '14px',
}

const mapStateToProps = state => {
  const { profile } = state.user;
  return {
    loginModalIsOpen: state.modals.loginModalIsOpen,//state.modals.loginModalIsOpen
    userDropdownIsOpen: state.modals.userDropdownIsOpen,
    account: profile && profile.account,
    name: profile && profile.name,
    number: state.newupload.course.number
  };
};

const mapDispatchToProps = (dispatch) => ({
  openRoomModal(state) {
    dispatch(toggleRoom(state));
  },
  toggleLogin(state) {
    dispatch(toggleLogin(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
  updateCoursenumber(num) {
    dispatch(updateCoursenumber(num))
  },
  updateCourseInfo(title, brief) {
    dispatch(updateCourseInfo(title, brief))
  },
  updateCourseCover(cover) {
    dispatch(updateCourseCover(cover))
  },
  updateCourseDemo(demo) {
    dispatch(updateCourseDemo(demo))
  },
  initChapters(initchapters, prechaptertitles, prekeys, length) {
    dispatch(initChapters(initchapters, prechaptertitles, prekeys, length));
  },
});



axios.defaults.withCredentials = true;
class Nav extends React.Component {
  userRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      admin: false,
      upload: false,
      login: false,
      profile: false,
      uploadShow: false,
      profileShow: false,
      msgShow: false,
      userId: '',
      msgLastId: '',
      msgList: [],
      isShow: false,     // 判断移动端显示
      mobileShow: false, // 移动端点击下拉列表,
      permissions: false,   // 权限判断
      languageShow: false,   // 切换语言显示
      languageName: '中文'
    }
  }

  componentDidMount = () => {
    var _this = this;
    if (window.localStorage.language && JSON.parse(window.localStorage.language) === 1) {
      this.setState({
        languageName: "English"
      })
    } else {
      this.setState({
        languageName: "中文"
      })
    }
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    if (arr) {
      this.props.toggleLogin(false)
    } else {
      axios.get(`${config.env.server}getcookieuserid`).then(res => {
        if (res.data[0][0]) {
          this.props.toggleLogin(true)
          window.UserId = res.data[0][0].userid;
          window.localStorage['identity'] = JSON.stringify(res.data[0][0].identity)
          let show = false
          if (res.data[0][0].identity === 2 || res.data[0][0].identity === 0) {
            show = true
          } else {
            show = false
          }
          setTimeout(() => {
            _this.setState({
              userId: res.data[0][0].userid,
              permissions: show
            });
          }, 0);
        } else {
          this.props.toggleLogin(false)
        }
      }).catch(err => {
        this.props.toggleLogin(false)
      })
    }
    this.getMsg()
  }

  // 点击显示下拉列表
  mobileClick = () => {
    this.setState({
      mobileShow: !this.state.mobileShow
    })
  }

  getMsg = () => {
    var _timedate = window.U.UF.D.getFullTime(new Date);
    setInterval(() => {
      if (this.state.userId) {
        let mode = `${_timedate},${this.state.userId}`
        axios.post(`${config.env.edu}profile/usernewsbyid`, qs.stringify({ mode: mode })).then(res => {
          if (res.data) {
            this.setState({
              msgLastId: res.data[0][res.data[0].length - 1].newsid,
              msgList: res.data[0]
            })
            _timedate = window.U.UF.D.getFullTime(new Date)
          }
        }).catch(err => {

        })
      }
    }, 20000);
  }
  componentWillReceiveProps = () => {
    let identity = (window.localStorage.identity && JSON.parse(window.localStorage.identity))
    if (identity === 2 || identity === 0) {
      this.setState({
        permissions: true
      })
    }else {
      this.setState({
        permissions: false
      })
    }
    if (this.props.history.location.pathname === '/upload') {
      this.setState({
        upload: true,
        login: false,
        profile: false
      })
    } else if (this.props.history.location.pathname === '/profile') {
      this.props.updateCoursenumber(undefined);
      updateCourseInfo(null, null);
      updateCourseCover([]);
      updateCourseDemo([]);
      initChapters([], [{ title: '' }], [0]);
      this.setState({
        upload: false,
        login: false,
        profile: true
      })
    } else if (this.props.history.location.pathname === '/room') {
      this.props.updateCoursenumber(undefined);
      updateCourseInfo(null, null);
      updateCourseCover([]);
      updateCourseDemo([]);
      initChapters([], [{ title: '' }], [0]);
      this.setState({
        upload: false,
        login: false,
        profile: true
      })
    } else if (this.props.history.location.pathname === '/login') {
      this.props.updateCoursenumber(undefined)
      updateCourseInfo(null, null);
      updateCourseCover([]);
      updateCourseDemo([]);
      initChapters([], [{ title: '' }], [0]);
      this.setState({
        upload: false,
        login: true,
        profile: false
      })
    } else {
      this.setState({
        upload: false,
        login: false,
        profile: false
      })
    }
  }

  handleRoomModal = () => {
    this.props.openRoomModal(true);
  }

  userFrame = {
    open: () => {
      this.props.history.push('/login')
    },
    profile: () => {
      window.location.href = '/profile'
    },
    upload: () => {
      this.props.history.push('/upload')
    },
    recordUpload: () => {
      this.props.history.push('/recordUpload')
    },
    loginOut: () => {
      let _this = this
      axios.defaults.withCredentials = true;
      axios.post(`${config.env.server}logout`).then(res => {
        if (res.data) {
          _this.props.toggleLogin(false)
          _this.setState({ profileShow: false })
          localStorage.clear()
          if (window.QC) {
            // window.QC.Login({
            //   btnId: "qqLogin",	//插入按钮的节点id
            // }, (reqData, opts) => {
            //   window.QC.Login.getMe(function (openId, accessToken) {
            //     // thirdparty(null, null, reqData.figureurl_qq_2, reqData.nickname, 1, openId);
            //     window.QC.Login.signOut();//退出QQ登录调用事件
            //     // this.props.history.push('/login')
            //     // }
            //   })
            // });
            if (window.QC.Login.check()) {//检查是否已登录
              window.QC.Login.signOut(); //退出登录
            }

          }
          _this.props.history.push('/login')
        }
      }).catch(err => {
        if (err && err.response && err.response.data) {
          window.U.Alert(err.response.data);
        }
      })
    },
    addRoom: () => {
      this.props.history.push('/room')
    },
    msg: (e) => {
      window.location.href = `/profile?newId=${e.newsid}&id=1`
    }
  }

  // 点击切换语言
  switchLanguage = (type) => {
    if (type === 'English') {
      window.localStorage['language'] = 1
    } else {
      window.localStorage['language'] = 2
    }
    this.setState({
      languageName: type,
      languageShow: false
    })
    this.props.getLanguage(type)
    // 切换完语言刷新页面
    window.location.reload();
  }


  uploadChange = event => {
    this.setState({
      uploadShow: true,
    })
  };

  render() {
    const { loginModalIsOpen, language } = this.props;
    const { msgList, permissions, msgShow, uploadShow, profileShow, mobileShow, languageShow, languageName } = this.state
    return (
      <Wrapper areaname={this.props.areaname}>
        <Link to='/' css={logoStyle} >
          {/* <Logo /> */}
          <img src={require('../assets/img/logo1.png')} style={{width:120}} />
        </Link>
        <span className='span' onClick={this.mobileClick} style={{ position: 'absolute', right: 15, top: 8 }}><img src={require('../assets/img/mobile.png')} /></span>
        <User className='user'>
          <ul className={loginModalIsOpen ? 'loginStyle' : 'ulStyle'}>

            {/* <li css={liStyle} onClick={this.handleRoomModal} style={{ cursor: 'pointer', margin: '0px 15px' }}>
              <div><span css={buttonStyle}>
                {language.enter_the_room}
              </span></div>
            </li> */}
            {(loginModalIsOpen && permissions) ? <li css={liStyle} onMouseMove={this.uploadChange} onMouseLeave={() => { this.setState({ uploadShow: false }) }}>
              <img src={require('../assets/img/headerUpload.png')} style={{ position: 'relative', width: "25px", top: 7, left: '10px' }} />
              <span style={{ padding: '8px 15px' }}>{language.upload_to_create}</span>
              {uploadShow ? <ul className='style' id="upload">
                <li style={{ padding: '10px 0', cursor: 'pointer' }} onClick={this.userFrame.upload}>{language.upload_course}</li>
                <li style={{ padding: '10px 0', cursor: 'pointer', borderTop: '1px solid #eee', }} onClick={this.userFrame.recordUpload}>{language.upload_record_course}</li>
                <li style={{ padding: '10px 0', cursor: 'pointer', borderTop: '1px solid #eee', }} onClick={this.userFrame.addRoom}>{language.create_room}</li>
              </ul> : ''}
            </li> : ''}
            {loginModalIsOpen ? <li css={liStyle} onMouseMove={() => { this.setState({ profileShow: true }) }} onMouseLeave={() => { this.setState({ profileShow: false }) }}>
              <img src={require('../assets/img/headerMy.png')} style={{ position: 'relative', width: '30px', top: 10, left: '10px' }} />
              <span style={{ fontWeight: 'blod', padding: '8px 15px' }}>
                {language.my_room}
              </span>
              {profileShow ? <ul className='style' id="upload">
                <li style={{ padding: '10px 0', cursor: 'pointer' }} onClick={this.userFrame.profile}>{language.personal_center}</li>
                <li style={{ padding: '10px 0', borderTop: '1px solid #eee', cursor: 'pointer' }} onClick={this.handleRoomModal}>{language.enter_the_room}</li>
                <li style={{ padding: '10px 0', borderTop: '1px solid #eee', cursor: 'pointer' }} onClick={this.userFrame.loginOut}>{language.logout}</li>
              </ul> : ''}
            </li> : ''}
            <li style={{ width: 130 }} css={liStyle} onMouseMove={() => { this.setState({ msgShow: true }) }} onMouseLeave={() => { this.setState({ msgShow: false }) }}>
              {loginModalIsOpen ? <div>
                <img src={require('../assets/img/headerMsg.png')} style={{ position: 'relative', width: "30px", top: 10, left: '-35px' }} />
                {msgList.length > 0 ? <span style={{ display: 'block', width: 5, height: 5, background: 'red', position: 'absolute', top: 25, left: 23, borderRadius: "50%" }}></span> : ''}
                {msgShow ? <ul css={msgStyle} id="upload">
                  <li style={{ padding: '10px 5px', textAlign: 'left', }}>
                    <p style={{ fontWeight: 'bold' }}>{language.notification_center} <span style={{ float: 'right', fontWeight: 'normal' }}>({msgList.length}{language.message})</span></p>
                    {msgList.length ? msgList.map(x => {
                      return (
                        <div onClick={() => this.userFrame.msg(x)} key={x.newsid} style={{ fontSize: 13, padding: 5, margin: '5px 0', borderTop: '1px solid #ccc', maxHeight: 40, overflow: 'hidden', cursor: 'pointer', lineHeight: '1.25', fontWeight: 'bold' }}>{x.newscontent}</div>
                      )
                    }) : ''}
                  </li>
                </ul> : ''}
              </div> : <span onClick={this.userFrame.open}>{language.login}</span>}
            </li>
          </ul>
          <div className="switchLanguage" onMouseMove={() => { this.setState({ languageShow: true }) }} onMouseLeave={() => { this.setState({ languageShow: false }) }}>
            <span css={languages} >
              {languageName}
            </span>
            {languageShow ? <ul>
              <li onClick={() => this.switchLanguage('中文')}>中文</li>
              <li style={{ borderTop: '1px solid #ddd' }} onClick={() => this.switchLanguage('English')}>English </li>
            </ul> : ""}
          </div>
        </User>
        {mobileShow ? <ul className='loginStyles'>
          <li css={liStyle} onClick={this.handleRoomModal} style={{ cursor: 'pointer' }}>
            <div><span css={buttonStyle}>
              {language.enter_the_room}
            </span></div>
          </li>

          {(loginModalIsOpen && permissions) ? <li css={liStyle} onMouseMove={this.uploadChange} onMouseLeave={() => { this.setState({ uploadShow: false }) }}>
            <img src={require('../assets/img/headerUpload.png')} style={{ position: 'relative', width: "25px", top: 7, left: '-10px' }} />
            <span>{language.upload_to_create}</span>
            {uploadShow ? <ul className='style' id="upload">
              <li style={{ padding: '10px 18px', cursor: 'pointer' }} onClick={this.userFrame.upload}>{language.upload_course}</li>
              <li style={{ padding: '10px 18px', cursor: 'pointer', borderTop: '1px solid #eee', }} onClick={this.userFrame.recordUpload}>{language.upload_record_course}</li>
              <li style={{ padding: '10px 18px', cursor: 'pointer', borderTop: '1px solid #eee', }} onClick={this.userFrame.addRoom}>{language.create_room}</li>
            </ul> : ''}
          </li> : ''}
          {loginModalIsOpen ? <li css={liStyle} onMouseMove={() => { this.setState({ profileShow: true }) }} onMouseLeave={() => { this.setState({ profileShow: false }) }}>
            <img src={require('../assets/img/headerMy.png')} style={{ position: 'relative', width: '30px', top: 10, left: '-10px' }} />
            <span style={{ fontWeight: 'blod' }}>
              {language.my_room}
            </span>
            {profileShow ? <ul className='style' id="upload">
              <li style={{ padding: '10px 18px', cursor: 'pointer' }} onClick={this.userFrame.profile}>{language.personal_center}</li>
              <li style={{ padding: '10px 18px', borderTop: '1px solid #eee', cursor: 'pointer' }} onClick={this.userFrame.loginOut}>{language.logout}</li>
            </ul> : ''}
          </li> : ''}
          <li css={liStyle} onMouseMove={() => { this.setState({ msgShow: true }) }} onMouseLeave={() => { this.setState({ msgShow: false }) }}>
            {loginModalIsOpen ? <div>
              <span>通知中心</span>
              {msgList.length > 0 ? <span style={{ display: 'block', width: 5, height: 5, background: 'red', position: 'absolute', top: 25, left: 23, borderRadius: "50%" }}></span> : ''}
              {msgShow ? <ul className='style' id="upload">
                <li style={{ padding: '10px 5px', textAlign: 'left' }}>
                  <p style={{ fontWeight: 'bold' }}>{language.notification_center} <span style={{ float: 'right', fontWeight: 'normal' }}>({msgList.length}{language.message})</span></p>
                  {msgList.length ? msgList.map(x => {
                    return (
                      <div onClick={() => this.userFrame.msg(x)} key={x.newsid} style={{ fontSize: 13, padding: 5, margin: '5px 0', borderTop: '1px solid #ccc', maxHeight: 40, overflow: 'hidden', cursor: 'pointer', lineHeight: '1.25', fontWeight: 'bold' }}>{x.newscontent}</div>
                    )
                  }) : ''}
                </li>
              </ul> : ''}
            </div> : <span onClick={this.userFrame.open}>{language.login}</span>}
          </li>
        </ul> : ''}
        <RoomModal location={this.props.location} language={language} />
      </Wrapper >
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
