import React from 'react'
import styled from 'styled-components'
//config
import config from '../config'
//components
import Button from '../components/Button'
import Clip from '../components/Clip'
import Title from '../components/Title'
import Subtitle from '../components/Subtitle'
import Subtitle2 from '../components/Subtitle2'
import Content from '../components/Content'
import Chapter from '../components/Chapter'
import SubscribeModal from '../components/SubscribeModal'
//router
import { Link, Redirect } from 'react-router-dom'
// axios
import { get, post } from 'axios';
import qs from 'qs';
// connect
import { connect } from 'react-redux';
// action
import { setCourse, toggleSubscribe, toggleUserDropdown } from '../redux/Actions';

import Modal from '@material-ui/core/Modal';
import axios from 'axios';
// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");

const Wrapper = styled.div`
text-align:center;
  width:80%;
  height:100%;
  margin:0 auto;
  grid-area:${props => props.areaname};
  display:grid;
  grid-template-columns:repeat(4, 25%);
  grid-template-rows: 50px 50px auto auto 1fr auto 50px 50px;
    grid-template-areas:
        '. . . .'
        'title title title title'
        'subtitle subtitle subtitle subtitle'
        'brief brief brief .'
        'clip clip clip chapters'
        '. . . chapters'
        'aaa preBtn nextBtn chapters'
        '. . . .';
  grid-gap:20px;
  @media screen and (max-width:1023px){
    grid-template-columns:repeat(4, 22%);
    width:90%;
    grid-gap:10px 4%;
grid-template-rows: 30px 50px auto auto 1fr 50px auto 50px 50px 50px;
    grid-template-areas:
        '. . . .'
        'title title title title'
        'subtitle subtitle subtitle subtitle'
        'brief brief brief brief'
        'clip clip clip clip'
        '. . . .'
        'chapters chapters chapters chapters'
        '. . . .'
        'preBtn preBtn nextBtn nextBtn'
        '. . . .';
  }
`;

const ClipContainer = styled.div`
  grid-area: clip;
  padding-top: calc(100%*9/16);
  position:relative;
  height: fit-content;
`;

const ChapterContainer = styled.div`
  grid-area: chapters;
`;

const Type = styled.div`
  grid-area: subtitle;
`;

const mapStateToProps = state => ({
  ...state.courses,
  loginModalIsOpen: state.modals.loginModalIsOpen,
});

const mapDispatchToProps = dispatch => ({
  changeCourseData(data) {
    dispatch(setCourse(data));
  },
  toggleModal(state) {
    dispatch(toggleSubscribe(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});

class CourseIntro extends React.Component {
  state = {
    redirect: false,
    collection: false,
    userId: '',
    subscribe: false,
    loading: false,
    user: {},
    isJuri: '',
    typeAarry:[],
    ptypeArray:[],
    urlToken:true
  };

  redirect = () => {
    this.setState({ redirect: true });
  };

  
  // url 取值
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }

  componentDidMount = async () => {
    document.title = "课程详情"
    // setTimeout(()=>{
    //   this.props.openUserDropdown(false)
    // },500)
    var _this = this
    window.scrollTo(0, 0);
    const { match, changeCourseData } = this.props;
    const url = `${config.env.edu}course/${match.params.courseId}`;
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    // http://localhost:3000/course/316?token=frMS4tqweKgl6GCJ
    // http://localhost:3000/course/102?token=frMS4tqweKgl6GCJ
    // https://edu.api.cocorobo.cn/api/authorize?token=frMS4tqweKgl6GCJ
    let Token = await axios.get(`https://api.edu.cocorobo.cn/api/authorize?token=${this.getQueryString('token')}`).then(res=>res.data)
    if (Token){
      // console.log(Token)
      await get(url).then(res => (res.data[0][0].chapters = JSON.parse(res.data[0][0].chapters),
            changeCourseData(res.data[0][0]),
            this.setState({
              typeAarry: res.data[1],
              ptypeArray: res.data[2],
              urlToken:Token
            })
          )).catch(err => (_this.redirect()));
    }
    else  {
      this.props.history.push('/401') 
    }
    await this.setState({
      loading: false
    })
  }

  // 判断是否收藏
  getColl = (userId) => {
    get(`${config.env.edu}course/${userId}/${this.props.match.params.courseId}`).then(res => {
      if (res.data[0][0]) {
        this.setState({
          collection: res.data[0][0].iscollection === 1 ? true : false,
          subscribe: res.data[0][0].issubscribe === 1 ? true : false,
        })
      }
    })
  }

  // 点击收藏
  collection = () => {
    let mode = `mode=,${this.state.userId},${this.props.match.params.courseId}`
    post(`${config.env.edu}collection/create`, mode).then(res => {
      if (res.data) {
        this.setState({
          collection: !this.state.collection
        })
      }
    }).catch(err => {
      if (err.response.data) {
        alert(err.response.data)
      }
    })
  }

  // 点击订阅
  subscribe = () => {
    let mode = `mode=,${this.state.userId},${this.props.match.params.courseId}`
    post(`${config.env.edu}subscribe/create`, mode).then(res => {
      if (res.data) {
        this.setState({
          subscribe: !this.state.subscribe
        })
      }
    }).catch(err => {
      if (err.response.data) {
        alert(err.response.data)
      }
    })
  }

  // sendGAEvent = () => {
  //   console.log(window.gtag)
  //   window.gtag('event', '點擊', {
  //     'event_category': '訂閱按鈕',
  //     'event_label': '課程'
  //   });
  // }

  componentWillUnmount = () => {
    this.props.openUserDropdown(true)
  }

  render() {
    const { loginModalIsOpen, state, language } = this.props
    const { subscribe, user, userId, typeAarry,ptypeArray,urlToken } = this.state
    return (
      <Wrapper areaname={this.props.areaname}>
        <ClipContainer>
          <Clip
            areaname=''
            source={this.props.demo}
            poster={this.props.poster}
            state={this.props.state}
            backgroundColor='#45699A'
            css={`
              padding: 10px;
              box-sizing: border-box;
              position: absolute;
              top: 0;
              left: 0;
            `}
          />
        </ClipContainer>
        <Title
          language={language}
          state={state}
          areaname='title'
          css={`
            text-align:left;
          `}
        />
        {/* <Subtitle2
          areaname='subtitle'
          name={`By ${this.props.author ? this.props.author : '可可乐博'}`}
          type={typeAarry}
          ptype={ptypeArray}
          css={`
            text-align:left;
          `}
        /> */}
        <Content
          areaname='brief'
          object={this.props.chapters}
          text={this.props.state === 2 ? JSON.parse(this.props.brief).brief : this.props.brief}
          css={`
            text-align:left;
            box-sizing:border-box;
            padding-right:5%;
          `}
        />
        <ChapterContainer>
          <Chapter
            areaname=''
            courseId={this.props.courseId}
            chapters={this.props.chapters}
            state={this.props.state}
            match={this.props.match}
          />
        </ChapterContainer>
        {loginModalIsOpen ? <Button
          onClick={this.collection}
          name={this.state.collection ? `${language.course_already_collection}` : `${language.course_collection}`}
          primary
          css={`
                grid-area: aaa;
                background:${this.state.collection ? '#fff' : 'rgb(253,171,29)'};
                box-shadow:3px 3px 0 rgb(253,171,29);
                width: 50%;
                color:${this.state.collection ? 'rgb(253,171,29)' : '#fff'};
                border:2px solid rgb(253,171,29);
                @media screen and (max-width:1023px){
                  min-width: 100%;
                }
                `} /> : ""}

        <Link to={'/'}
          css={`
            grid-area: preBtn;
            text-decoration: none;
            display: flex;
            justify-content: flex-end;
          `}>
          <Button
            name={language.return_home}
            secondary
            css={`
              min-width: 50%;
              margin-rigt:0;
              @media screen and (max-width:1023px){
                min-width: 100%;
              }`} />
        </Link>
        {/* <Link to={`${this.props.match.url}/chapter`}
          css={`
              grid-area: nextBtn;
              text-decoration: none;
            `}> </Link> */}
        {state !== 0 ?
          <Button
            onClick={() => {
              if ((this.state.isJuri == 0 && this.props.state === 2 && this.props.userid != userId) && !urlToken) {
                // window.alert("你的账号对此课程没有访问权限了，请去联系课程老师添加")
                // return;
                this.props.history.push(`/examine/${this.props.match.params.courseId}/${userId}`)
              } else if (this.props.state === 1) {
                this.props.history.push(`${this.props.match.url}/chapter${urlToken?`?token=${this.getQueryString('token')}`:''}`)
              } else if (this.props.state === 2) {
                console.log(`${config.env.basename}/tutor/recorded/${this.props.match.params.courseId}`)
                // window.location.href = `${config.env.basename}/tutor/recorded/${this.props.match.params.courseId}`
                this.props.history.push(`/courseClassify?number=${this.props.match.params.courseId}`)
              }
            }}
            name={this.props.state === 1 ? language.info_course : language.info_record}
            primary
            css={`
                min-width: 50%;
                grid-area: nextBtn;
                margin:0 auto;
                @media screen and (max-width:1023px){
                  min-width: 100%;
                }
                `} />
          :
          <Button
            name={subscribe ? `${language.course_already_subscribe}` : `${language.course_subscribe}`}
            primary
            onClick={this.subscribe}
            css={`
                grid-area: nextBtn;
                min-width: 50%;
                margin:0 auto;
                @media screen and (max-width:1023px){
                  min-width: 100%;
                }
                `} />}
        <SubscribeModal match={this.props.match} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.loading}
          onClose={() => {
            this.setState({
              loading: true
            })
          }}
        >
          <div style={{ width: '100%', height: '100%' }}>
            <img src={require('../assets/img/loading1.gif')} style={{ width: 500, height: 300, position: 'absolute', top: 'calc(50% - 150px)', left: 'calc(50% - 250px)' }} />
            <p style={{ position: 'absolute', width: '100%', top: 'calc(50% + 75px)', textAlign: 'center', color: '#fff' }}>{language.loadings}</p>
          </div>
        </Modal>
      </Wrapper>
    )
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(CourseIntro);
