import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from 'axios'
import styled from 'styled-components'
import '../css/examine.css'
import config from '../config'
import qs from 'qs';


let eye = require("../assets/img/examine/examine.png")

const Wrapper = styled.div`
 
`;

const App = styled.div`
`

const CourseCardContainer = styled.div`
  grid-area:${props => props.areaname};
  display:grid;
  grid-template-columns:1fr 1fr 1fr 1fr;
  grid-gap:30px 20px;
  @media screen and (max-width:1023px){
    grid-template-columns:1fr 1fr;
  }
`
const Panel = styled.div`
  background:#fff;
  width:500px;
  min-height:50px;
  border-radius:5px;
  box-shadow:0px 0px 10px 3px rgb(0 0 0 / 15%);
  font-size:16px;
  padding:20px 10px;
`

class Examine extends React.Component {
  state = { name: "", school: "", identity: "", phone: "" }

  componentDidMount() {

  }

  returnHome = (that) => {
    that.props.history.push('/');
  }

  send = () => {
    if (this.state.name == '' || this.state.school == '' || this.state.school == '' || this.state.phone == '') {
      window.U.Alert('请填写完整的信息')
      return;
    }

    let params = {
      uid: this.props.match.params.userId,
      cid: this.props.match.params.courseId,
      n: this.state.name,
      s: this.state.school,
      i: this.state.identity,
      p: this.state.phone,
    }
    var a = "http://localhost:7001/edu/" // config.env.edu
    axios.post(`${config.env.edu}sendExamine`, qs.stringify(params)).then(res => {
      console.log(res)
      window.U.Alert('提交成功')
      this.props.history.push('/');
    })
  }

  componentWillMount = () => {
    let _this = this
    window.addEventListener("popstate", function (e) {
      _this.props.history.push('/')
    }, false);
  }

  render() {
    const { banners, app, language } = this.props
    return (
      <div>
        <Wrapper areaname={this.props.areaname}>
          <App>
            <div style={{ height: 'calc(100vh - 135px)', position: 'relative', backgroundColor: 'rgb(246,246,246)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Panel>
                <div className="e_imgB">
                  <img src={eye} />
                  <div>
                    您暂时没有权限访问课程哦，可提交信息进行申请，我们会尽快回复您！
                  </div>
                </div>

                <div className="e_inputB">
                  <div className="e_inputC">
                    <div className="e_inputS e_must">真实姓名</div>
                    <div className="e_inputI"><input type="input" placeholder='请输入真实姓名' onChange={e => { this.setState({ name: e.target.value }) }} /></div>
                  </div>
                  <div className="e_inputC">
                    <div className="e_inputS e_must">学校</div>
                    <div className="e_inputI"><input type="input" placeholder='请输入学校' onChange={e => { this.setState({ school: e.target.value }) }} /></div>
                  </div>
                  <div className="e_inputC">
                    <div className="e_inputS e_must">身份</div>
                    <div className="e_inputI"><input type="input" placeholder='请输入身份' onChange={e => { this.setState({ identity: e.target.value }) }} /></div>
                  </div>
                  <div className="e_inputC">
                    <div className="e_inputS e_must">联系方式</div>
                    <div className="e_inputI"><input type="input" placeholder='请输入手机号或者邮箱' onChange={e => { this.setState({ phone: e.target.value }) }} /></div>
                  </div>
                  <div className="e_buttonB">
                    <span className='e_button' onClick={this.send}>确定提交</span>
                  </div>
                </div>
              </Panel>
            </div>
          </App>
        </Wrapper>
      </div>
    )
  }
}

export default connect()(Examine);
