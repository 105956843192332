import React from 'react'
import styled from 'styled-components'
import { Route, Switch } from 'react-router-dom'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core'
//components
import CourseInfo from '../components/CourseInfoUpload'
import ChapterInfo from '../components/ChapterInfoUpload'
import Chapter from '../components/ChapterUpload'
import Steppers from '../components/Steppers'
import Result from '../components/Result'
import Coursecheck from '../components/Coursecheck'
import axios from 'axios'
import config from '../config'

// cookie
const reg = new RegExp("(^| )" + 'cocorobo' + "=([^;]*)(;|$)");
const Wrapper = styled.div`
  display: grid;
  grid-area: ${props => props.areaname};
  grid-template-columns: 25% 75%;
  grid-template-areas:
  'steppers uploadArea';
  overflow: auto;
  height: calc(100vh - 135px);
}
`;

const UploadArea = styled.div`
  overflow: auto;
  display: grid;
  grid-area: uploadArea;
`;

const SteppersArea = styled.div`
  overflow: hidden;
  display: grid;
  grid-template-columns: calc(100% / 3);
  grid-template-rows: 40px 60px 1fr 1fr 1fr;
  grid-template-areas:
  '. . .'
  '. text text'
  '. steppers steppers'
  '. steppers steppers'
  '. steppers steppers'
  ;
  border-right:1px solid #3c5e8f;
`;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3D67BC'
    }
  },
  typography: {
    useNextVariants: true
  },
});
const Text = styled.div`
  grid-area: text;
  text-align:left;
`;
class CourseUpload extends React.Component {

  state = {
    userId: '',
    uploadid: window.Guid.newGuid() //上传的时候唯一识别id
  }

  componentDidMount() {
    var _this = this
    document.title = "上传课程"
    window.localStorage['uploadid'] = this.state.uploadid;
    let arr = document.cookie.match(reg) && document.cookie.match(reg)[2]
    if (arr) {
      this.props.history.push('/login')
    }
    else {
      axios.defaults.withCredentials = true;
      axios.get(`${config.env.server}getcookieuserid`).then(res => {
        if (res.data[0][0].identity === 1) {
          _this.props.history.push('/login')
          return;
        }
        _this.setState({
          userId: res.data[0][0].userid
        });
      }).catch(err => {
        _this.props.history.push('/login');
        //window.alert(err);
      })
    }
  }

  handleScroll = (event) => {
    if (this.handleScroll.chapterscroll) {
      this.handleScroll.chapterscroll(event);
    }
  }
  render() {
    const { language } = this.props
    return (
      <Wrapper areaname={this.props.areaname} >
        <MuiThemeProvider theme={theme}>
          <SteppersArea>
            <Text>
              <div css={`color: rgba(128,128,128);`}
              >{language.upload_steps}:</div>
            </Text>
            <Steppers
              steps={[`${language.basic_information}`, `${language.upload_course_content}`, `${language.check_course_content}`, `${language.upload_complete}`]}
              stepsContent={['', ' ', ' ', ' ']}
            />
          </SteppersArea>
          <UploadArea onScroll={this.handleScroll}>
            <Switch>
              <Route
                path='/upload/chapter/name'
                render={({ match, history }) => (<ChapterInfo uploadid={this.state.uploadid} history={history} match={match} language={language} />)}
              />
              <Route
                path='/upload/chapter/edit'
                render={({ match, history }) => (<Chapter uploadid={this.state.uploadid} handleScroll={this.handleScroll} history={history} match={match} language={language} />)}
              />
              <Route
                path='/upload/chapter/check'
                render={({ match, history }) => (<Coursecheck uploadid={this.state.uploadid} handleScroll={this.handleScroll} userId={this.state.userId} history={history} match={match} language={language} />)}
              />
              <Route
                path='/upload/result/:courseId'
                render={({ match, history }) => (<Result history={history} uploadid={this.state.uploadid} match={match} language={language} />)}
              />
              <Route render={({ match, history }) => (<CourseInfo uploadid={this.state.uploadid} history={history} match={match} language={language} />)} />
            </Switch>
          </UploadArea>
        </MuiThemeProvider>
      </Wrapper>
    );
  }
}

export default CourseUpload;
