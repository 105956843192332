import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import '../css/login.css';
import { RGCaptcha, reset } from 'react-geetest-captcha';
import axios from 'axios';
import qs from 'qs';
import UserAgreement from '../components/UserAgreement'
import PrivacyAgreement from '../components/PrivacyAgreement'
import config from '../config'
import { toggleRoom, toggleLogin, toggleUserDropdown } from '../redux/Actions';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { Popover, CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getSchoolLists, geetestData, getWeixinCode } from '../http/api'

const CAPTCHA_NAME = 'demoCaptcha';
const mapDispatchToProps = (dispatch) => ({
  openRoomModal(state) {
    dispatch(toggleRoom(state));
  },
  toggleLogin(state) {
    dispatch(toggleLogin(state));
  },
  openUserDropdown(state) {
    dispatch(toggleUserDropdown(state));
  },
});
const mapStateToProps = state => {
  const { profile } = state.user;
  return {
    loginModalIsOpen: state.modals.loginModalIsOpen,
    account: profile && profile.account,
    name: profile && profile.name
  };
};
const regEmail = new RegExp("^[A-Za-z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$");
const regpass = new RegExp(/^(?=.*\d)(?=.*[A-Za-z]).{6,}$/);
// const useStyles = makeStyles({
//   progress: {
//     margin: theme.spacing(2),
//   },
// });

@withRouter
class login extends React.Component {
  // const [completed, setCompleted] = useState(0)
  constructor(props) {
    super(props)
    this.state = {
      login: true,
      schoolShow: false,  // 學校列表顯示
      list: [
      ],
      schoolName: '',   // 學校名稱
      schoolId: '',    // 選中的學校id
      value: '',  // 學校
      identityValue: 1,   // 選擇的身份
      identityShow: false,   // 身份選擇下拉列表
      userEmail: '',     // 用戶登錄郵箱
      userPassWord: '',    // 用戶登錄密碼
      registereEmail: '',    // 用戶註冊郵箱
      registerePassWord: '',    // 用戶註冊密碼
      progress: 0,         // 进度条
      title: '',
      progressShow: false,
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: '',
      isread: false,
      open: false,
      open2: false,
      type: "",
    }
  }

  componentDidMount() {
    var _type = window.U.UF.C.queryString("type") || '';
    if (_type == 1 || _type == 2) {
      // window.location.href = "https://fangzhen.cocorobo.cn"
      this.setState({
        type: _type
      })
    }
    let that = this
    if (window.QC) {
      window.QC.Login({
        btnId: "qqLogin",	//插入按钮的节点id
      }, (e) => {
        window.QC.Login.getMe(function (openId, accessToken) {
          let params = {
            openid: openId
          }
          console.log("当前登录用户的openId为：" + openId);
          // if (!arr) {
          window.openId = openId;
          axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {

            if (res.data[0][0].active === 1) {
              that.props.toggleLogin(true)
              if (that.state.type == 1) {
                var _UnityType = window.U.UF.C.queryString("UnityType") || '';
                if(_UnityType){
                  window.location.href = "https://fangzhen.cocorobo.cn/unity?type=" + _UnityType
                }else{
                  window.location.href = "https://fangzhen.cocorobo.cn"
                }
              } else if (that.state.type == 2) {
                // window.location.href = "https://fangzhen.cocorobo.cn"
              } else {
                that.props.history.push('/')
              }
            }
          }).catch(err => {
            if (err && err.response && err.response.status === 401) {
              that.props.history.push('/thirdlogin')
            }
          })
          // }
        })
      });
    }
    if (this.getQueryString('code') && this.getQueryString('state')) {
      // oiztX1dwR-W2mBJ5HcvaSEB8yKGY
      axios.defaults.withCredentials = false;
      axios.get(`//weixin.cocorobo.cn/login.php?code=${this.getQueryString('code')}`).then(res => {
        if (res.data && res.data.openid) {
          let params = {
            openid: res.data.openid
          }
          window.openId = res.data.openid;
          axios.defaults.withCredentials = true;
          axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {
            if (res.data && res.data[0] && res.data[0][0] && res.data[0][0].active === 1) {
              that.props.toggleLogin(true)
              if (that.state.type == 1) {
                var _UnityType = window.U.UF.C.queryString("UnityType") || '';
                if(_UnityType){
                  window.location.href = "https://fangzhen.cocorobo.cn/unity?type=" + _UnityType
                }else{
                  window.location.href = "https://fangzhen.cocorobo.cn"
                }
              } else if (that.state.type == 2) {
                // window.location.href = "https://fangzhen.cocorobo.cn"
              } else {
                that.props.history.push('/')
              }
            }
          }).catch(err => {
            console.log(err.response)
            if (err && err.response && err.response.status === 401) {
              that.props.history.push('/thirdlogin')
            }
          })
        }
      })
    }
    this.getSchoolList('')
    var Height = document.documentElement.clientHeight
    var font = document.getElementsByClassName('login')[0];
    font.style.height = (Height - 150) + 'px';
    function blue() {
      // font.style.color = 'blue';
    }
    font.onclick = function () {
      blue();
    }
    //this.geetestRegister();
  }

  // url 取值
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
  }

  geetestRegister() {
    reset(CAPTCHA_NAME);
  }

  geetestValidate(isSuccess) {
    this.setState({
      geetest_challenge: isSuccess.geetest_challenge,
      geetest_validate: isSuccess.geetest_validate,
      geetest_seccode: isSuccess.geetest_seccode
    })
  }

  geetest = () => {
    return geetestData().then((res) => {
      var captcha = (res && res.data) || {};
      return captcha;
    })
      .catch((err) => {
        console.log(err);
      })
    // return axios.get(`${config.env.api}geetest/pc-geetest/register`)
    //   .then((res) => {
    //     var captcha = (res && res.data) || {};
    //     return captcha;
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   })
  }

  // 獲取學校列表
  getSchoolList = e => {
    getSchoolLists({ mode: e }).then(res => {
      this.setState({
        list: res.data[0]
      })
    })
  }

  progress = (n) => {
    let num = this.state.progress + n;
    num = num > 100 ? 0 : num
    this.setState({
      progress: num
    })
  }

  // 用戶登錄郵箱驗證
  loginEmail = e => {
    // 清楚首尾空格
    let str = e.target.value.replace(/(^\s*)|(\s*$)/g, "")
    if (!(regEmail.test(str) || (/^1(3|4|5|6|7|8|9)\d{9}$/.test(str)))) {
      window.U.Alert(this.props.language.email_regular)

    }
    // else {
    //   this.setState({
    //     userEmail: e.target.value
    //   })
    // }
  }

  // 用戶登錄密碼 
  loginPassWord = e => {
    this.setState({
      userPassWord: e.target.value
    })
  }

  // 點擊獲取學校列表
  schoolList = e => {
    this.setState({
      schoolShow: true,
    })
  }

  // 根據輸入的值動態獲取學校列表
  changeList = e => {
    // 根據輸入的值動態獲取學校列表
    this.getSchoolList(e.target.value)
    this.setState({
      value: e.target.value,
      schoolName: '',
      schoolShow: true,
      schoolId: ''
    })
  }

  // 點擊學校列表，獲取學校名稱
  clickList = (e) => {
    this.setState({
      value: e.name,
      schoolShow: false,
      schoolId: e.id
    })
  }

  // 點擊彈出身份列表
  identityShow = () => {
    const { identityShow } = this.state
    this.setState({
      identityShow: !identityShow
    })
  }

  // 點擊顯示登陸還是註冊
  loginShow = () => {
    const { login } = this.state
    this.setState({
      login: !login,
      geetest_challenge: '',
      geetest_validate: '',
      geetest_seccode: ''
    });
    this.geetestRegister()
  }

  // 註冊
  registered = () => {
    const { language } = this.props
    if (this.state.geetest_challenge && this.state.geetest_validate && this.state.geetest_seccode) {
      const { schoolId, registereEmail, registerePassWord, value, progress, isread } = this.state
      let school = schoolId
      let registereEmails = registereEmail.replace(/(^\s*)|(\s*$)/g, "")
      if (!regEmail.test(registereEmails)) {
        window.U.Alert(language.registered_email_regular);
        return;
      }
      // !regpass.test(registerePassWord)
      if (registerePassWord.length < 6) {
        window.U.Alert(language.password_regular);
        return;
      }
      if (!isread) {
        window.U.Alert("请阅读并同意《用户注册协议》");
        return;
      }
      if (!school) {
        school = value
      }
      this.setState({
        progressShow: true
      })
      let aa = setInterval(() => {
        this.progress(10)
      }, 100);
      let params = {
        username: registereEmails,
        password: registerePassWord,
        googleId: '',
        identity: 1,
        college: school,
        geetest_challenge: this.state.geetest_challenge,
        geetest_validate: this.state.geetest_validate,
        geetest_seccode: this.state.geetest_seccode
      }
      var _that = this;
      axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false,
          progress: 100
        })
        clearInterval(aa)
        if (res.data[0][0].type === 1) {
          window.U.Alert(`<p>${language.registered_successful}</p><br/><img src='/img/loading.gif' />`, 1000);
          setTimeout(() => {
            _that.setState({
              login: true,
              progress: 0
            })
          }, 1000);
        } else if (res.data[0][0].type === 0) {
          window.U.Alert(language.registered_fail1);
        } else {
          window.U.Alert(language.registered_fail);
        }
      }).catch(err => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false
        }, () => {
          clearInterval(aa);
          if (err && err.response.data) {
            window.U.Alert(err.response.data);
          } else {
            window.U.Alert(language.registered_fail);
          }
        })
      })
    }
    else {
      window.U.Alert(`<p>${language.click_validation}</p>`, 1000);
    }
  }

  // 登錄
  login = () => {
    const { language } = this.props
    if (this.state.geetest_challenge && this.state.geetest_validate && this.state.geetest_seccode) {
      // /user
      const { userEmail, userPassWord } = this.state
      let str = userEmail.replace(/(^\s*)|(\s*$)/g, "")
      if (!(regEmail.test(str) || (/^1(3|4|5|6|7|8|9)\d{9}$/.test(str)))) {
        window.U.Alert(language.email_regular);
        return;
      }
      // !regpass.test(userPassWord)
      if (userPassWord.length < 6) {
        window.U.Alert(language.password_regular);
        return;
      }
      this.setState({
        progressShow: true
      })
      let aa = setInterval(() => {
        this.progress(10)
      }, 100)
      let params = {
        geetest_challenge: this.state.geetest_challenge,
        geetest_validate: this.state.geetest_validate,
        geetest_seccode: this.state.geetest_seccode,
        loginUsername: str,
        loginPassword: userPassWord
      }
      axios.defaults.withCredentials = true;
      var _that = this;
      axios.post(`${config.env.api}api/user`, qs.stringify(params)).then(res => {
        _that.geetestRegister();
        if (res.data[0][0].active === 1) {
          _that.setState({
            progressShow: false,
            progress: 100
          }, () => {
            window.U.Alert(`<p>${language.login_successful}</p><br/><img src='/img/loading.gif' />`, 1000);
          })
          setTimeout(() => {
            window.localStorage['identity'] = JSON.stringify(res.data[0][0].identity)
            _that.props.toggleLogin(true)
            if (_that.state.type == 1) {
              var _UnityType = window.U.UF.C.queryString("UnityType") || '';
              if(_UnityType){
                window.location.href = "https://fangzhen.cocorobo.cn/unity?type=" + _UnityType
              }else{
                window.location.href = "https://fangzhen.cocorobo.cn"
              }
            } else if (_that.state.type == 2) {
              // window.location.href = "https://fangzhen.cocorobo.cn"
            } else {
              _that.props.history.push('/')
            }
            clearInterval(aa)
          }, 1000)
        } else {
          window.U.Alert(language.login_fail);
        }
      }).catch(err => {
        _that.geetestRegister();
        _that.setState({
          progressShow: false
        }, () => {
          console.log(err, 'err')
          try {
            window.U.Alert(err.response.data);
          } catch (error) {
            window.U.Alert(language.login_fail);
          }
        })
      })
    }
    else {
      window.U.Alert(`<p>${language.click_validation}</p>`, 1000);
    }
  }

  // 註冊郵箱驗證
  registereEmail = e => {
    let str = e.target.value.replace(/(^\s*)|(\s*$)/g, "")
    if (!regEmail.test(str)) {
      window.U.Alert(this.props.language.registered_email_regular);
    }
    // else {
    //   this.setState({
    //     registereEmail: e.target.value
    //   })
    // }
  }

  enter = (e) => {
    if (e.keyCode === 13) {
      this.login()
    }
  }

  // 註冊密碼驗證
  registerePassWord = e => {
    // !regpass.test(e.target.value)
    if (e.target.value.length < 6) {
      window.U.Alert(this.props.language.password_regular);
    }
    // else {
    //   this.setState({
    //     registerePassWord: e.target.value
    //   })
    // }
  }

  jumpWeixin = () => {
    window.location.href = "//cocorobo.cn/get-weixin-code.html?appid=wxe9d7fff3c659445f&redirect_uri=" + window.encodeURI("//edu.cocorobo.cn/login") + "&response_type=code&scope=snsapi_login&state=" + Math.floor(Math.random() * 1000000);
  }

  jumpQQ = () => {
    window.open('https://graph.qq.com/oauth2.0/authorize?client_id=101882546&response_type=token&scope=all&redirect_uri=https%3A%2F%2Fedu.cocorobo.cn%2Flogin', 'oauth2Login_10273', 'height=525,width=585, toolbar=no, menubar=no, scrollbars=no, status=no, location=yes, resizable=yes');
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  };

  handleClose2 = () => {
    this.setState({
      open2: false
    })
  };
  render() {
    const { login, schoolShow, list, schoolName, value, title, progressShow, userEmail, progress, registerePassWord, registereEmail, userPassWord, isread, open, open2 } = this.state
    const { language } = this.props
    return (
      <div className="login" onClick={(e) => {
        this.setState({
          schoolShow: false,
          identityShow: false
        })
      }}>
        <div className="contain" onKeyDown={this.enter} style={{ position: "absolute", top: "50%", marginTop: (login ? -218 : -232) + "px", left: 'calc(50% - 200px)' }}>
          {login ?
            <div style={{ padding: '0 20px', marginTop: '25px' }}>
              <div className="userLogin">
                <img src={require("../assets/img/email.png")} />
                <input placeholder={language.user_email} onBlur={this.loginEmail} value={userEmail} onChange={e => {
                  this.setState({
                    userEmail: e.target.value
                  })
                }} />
              </div>
              <div className="userLogin">
                <img src={require("../assets/img/password.png")} />
                <input placeholder={language.user_passworld} type="password" onChange={this.loginPassWord} value={userPassWord} />
              </div>
              <div>
                <Link to='/password'><p>{language.forget_passworld}</p></Link>
              </div>
            </div>
            :
            <div style={{ padding: '0 20px', marginTop: '25px' }}>
              <div className="userLogin">
                <img src={require("../assets/img/email.png")} />
                <input placeholder={language.electronic_email} onBlur={this.registereEmail} onChange={e => {
                  this.setState({
                    registereEmail: e.target.value
                  })
                }} value={registereEmail} />
              </div>
              <div className="userLogin">
                <img src={require("../assets/img/password.png")} />
                <input type="password" placeholder={language.user_passworld_registered} onBlur={this.registerePassWord} onChange={e => {
                  this.setState({
                    registerePassWord: e.target.value
                  })
                }} value={registerePassWord} />
              </div>
              {/* <div className="userLogin" onClick={(e) => {
                e.stopPropagation()
              }}>
                <img src={require("../assets/img/schoo.png")} style={{ width: '23px', paddingRight: 3 }} />
                <input placeholder={language.user_school}
                  onClick={this.schoolList}
                  onChange={this.changeList}
                  onFocus={(e) => {
                    this.setState({ schoolShow: true })
                  }} value={schoolName ? schoolName : value} />
                {schoolShow ?
                  <ul className="selectList">
                    {list.length > 0 ? list.map(x => {
                      return (
                        <li key={x.id} onClick={() => this.clickList(x)}>{x.name}</li>
                      )
                    }) : <div style={{ textAlign: 'center' }}>{language.no_data}</div>}
                  </ul> : ''}
              </div> */}
              {/* <div className="identity" onClick={(e) => {
                e.stopPropagation()
              }}>
                <span>{language.identity_choice}：</span>
                <select name="" style={{ fontSize: 12, width: 75, outline: 'none' }} onClick={e => { this.setState({ identityValue: e.target.value }) }}>
                  <option value="0">{language.identity_teacher}</option>
                  <option value="1">{language.identity_student}</option>
                </select>
              </div> */}
              <div css={`display:flex;align-items: center;margin-top:10px`}>
                <input type="checkbox" css={`cursor:pointer;margin-right:5px`} onClick={() => { this.setState({ isread: !isread }) }} checked={isread} />
                <span>已经阅读并
                  <span css={`color:#2a97ff;cursor:pointer`} onClick={() => { this.setState({ open: true }) }}>《用户注册协议》</span>和<span css={`color:#2a97ff;cursor:pointer`} onClick={() => { this.setState({ open2: true }) }}>《隐私协议》</span></span>
              </div>
            </div>
          }

          <div className="Geetest" id="Geetest">
            <RGCaptcha
              name={CAPTCHA_NAME}
              width="100%"
              onSuccess={this.geetestValidate.bind(this)}
              data={this.geetest}
              lang={(window.localStorage.language && JSON.parse(window.localStorage.language) === 1) ? 'en' : 'zh-cn'}
            />
          </div>
          {
            login ?
              <div>
                <div className="loginUser">
                  <button onClick={this.login}>{language.login}</button>
                </div>
                <div className="guge">
                  <span>第三方登录</span>
                  <div style={{ display: 'inline-block' }}>
                    <span onClick={this.jumpWeixin}>
                      <img src={require('../assets/images/weixin.png')} />
                      微信
                    </span>
                    <span id="qqLogin" className="qqLogin" style={{ height: 21 }}>
                    </span>
                    <span onClick={this.jumpQQ}>
                      <img src={require('../assets/images/qq.png')} />
                      QQ
                    </span>
                  </div>
                </div>
                <p>{language.no_account}<span onClick={this.loginShow}>{language.click_registered}</span></p>
              </div>
              :
              <div>
                <div className="loginUser">
                  <button onClick={this.registered}>{language.registered}</button>
                </div>
                <div className="guge">
                  <button onClick={this.loginShow}>{language.login}</button>
                </div>
              </div>
          }
        </div>
        <Popover
          // id={id}
          open={progressShow}
          anchorOrigin={{
            vertical: 200,
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <div style={{ padding: '20px 30px', fontSize: 20 }}>
            <CircularProgress style={{ display: 'block', margin: '0 auto' }} variant="static" value={progress} />
          </div>
        </Popover>
        <Dialog
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="700px"
        >
          <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
            <div css={`color:#fff;font-size: 18px;`}>用户注册协议</div>
          </DialogTitle>
          <DialogContent style={{ background: "#fff" }}>
            <div css={`width:700px`}>
              <UserAgreement></UserAgreement>
            </div>
          </DialogContent>
          <DialogActions style={{ background: "#fff", padding: "8px 4px", margin: "0" }}>
            <div className="btnBox">
              <div></div>
              <div>
                <button className="info_btn" onClick={this.handleClose}>
                  关闭
                </button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open2}
          onClose={this.handleClose2}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="700px"
        >
          <DialogTitle id="alert-dialog-title" css={`background:#3D67BC;padding: 15px 20px 15px !important;`}>
            <div css={`color:#fff;font-size: 18px;`}>隐私协议</div>
          </DialogTitle>
          <DialogContent style={{ background: "#fff" }}>
            <div css={`width:700px`}>
              <PrivacyAgreement></PrivacyAgreement>
            </div>
          </DialogContent>
          <DialogActions style={{ background: "#fff", padding: "8px 4px", margin: "0" }}>
            <div className="btnBox">
              <div></div>
              <div>
                <button className="info_btn" onClick={this.handleClose2}>
                  关闭
                </button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(login);
